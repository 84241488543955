export const userData = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
        'https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/hbzdzch5ldxw6pszwmej.png',
}


const allAccess = ['estudiante', 'profesor', 'control escolar', 'administrativo', 'desarrollador', 'director', 'subdirector', 'coordinador', 'ventas', 'cobranza', 'invitado'];

const onlyCandidate = ['administrativo', 'desarrollador', 'director', 'subdirector', 'coordinador', 'ventas'];

const onlyCourse = ['estudiante', 'profesor', 'control escolar', 'administrativo', 'desarrollador', 'director', 'subdirector', 'cobranza', 'ventas'];

const onlyUsers = ['administrativo', 'desarrollador', 'director', 'subdirector'];

const onlyStudents = ['administrativo', 'control escolar', 'desarrollador', 'director', 'subdirector', 'cobranza', 'ventas'];

const onlyPayment = ['desarrollador', 'director', 'subdirector', 'ventas', 'cobranza'];

const onlySetting = ['desarrollador', 'director', 'subdirector'];

export const navigation = [
    { name: 'Inicio', href: '/', access: allAccess },
    { name: 'Candidatos', href: '/candidatos', access: onlyCandidate },
    { name: 'Cursos', href: '/cursos', access: onlyCourse },
    { name: 'Personal', href: '/usuarios', access: onlyUsers },
    { name: 'Alumnos', href: '/alumnos', access: onlyStudents },
    { name: 'Pagos', href: '/pagos', access: onlyPayment },
];

export const userNavigation = [
    { name: 'Perfil', href: '/mi-perfil', access: allAccess },
    { name: 'Configuracion', href: '/ajustes', access: onlySetting },
];

export const userMenu = [
    { name: 'Perfil', description: 'Edita y actuliza tu perfil de usuario.', href: 'mi-perfil', icon: "UserIcon", access: allAccess },
    { name: 'Cursos', description: 'Ver y gestionar tus cursos.', href: 'cursos', icon: "BookOpenIcon", access: onlyCourse },
    { name: 'Candidatos', description: 'Ver y validar a los nuevos candidatos', href: 'candidatos', icon: "UsersIcon", access: onlyCandidate },
    { name: 'Personal', description: 'Ver y gestionar al personal operativo', href: 'usuarios', icon: "UserGroupIcon", access: onlyUsers },
    { name: 'Estudiantes', description: 'Ver y gestionar a los estudiantes de la plataforma', href: 'alumnos', icon: "AcademicCapIcon", access: onlyStudents },
    { name: 'Pagos', description: 'Ver y validar pagos de suscripcion', href: 'pagos', icon: "CreditCardIcon", access: onlyPayment },
    { name: 'Configuracion', description: 'Personalizar la configuración de la aplicación', href: 'ajustes', icon: "Cog8ToothIcon", access: onlySetting },
    // { name: 'Notificaciones', description: "Administrar las notificaciones de la aplicación", href: 'notificaciones', icon: "BellIcon", access: onlyCourse },
    // { name: 'Tareas', description: 'Acceder y completar las tareas asignadas', href: 'tareas', icon: "RectangleStackIcon" },
];

export const locationState = [
    { value: "aguascalientes", description: "Aguascalientes" },
    { value: "baja california", description: "Baja California" },
    { value: "baja california sur", description: "Baja California Sur" },
    { value: "campeche", description: "Campeche" },
    { value: "coahuila", description: "Coahuila" },
    { value: "colima", description: "Colima" },
    { value: "chiapas", description: "Chiapas" },
    { value: "chihuahua", description: "Chihuahua" },
    { value: "ciudad de méxico", description: "Ciudad de México" },
    { value: "durango", description: "Durango" },
    { value: "guanajuato", description: "Guanajuato" },
    { value: "guerrero", description: "Guerrero" },
    { value: "hidalgo", description: "Hidalgo" },
    { value: "jalisco", description: "Jalisco" },
    { value: "estado de méxico", description: "Estado de México" },
    { value: "michoacán", description: "Michoacán" },
    { value: "morelos", description: "Morelos" },
    { value: "nayarit", description: "Nayarit" },
    { value: "nuevo león", description: "Nuevo León" },
    { value: "oaxaca", description: "Oaxaca" },
    { value: "puebla", description: "Puebla" },
    { value: "querétaro", description: "Querétaro" },
    { value: "quintana roo", description: "Quintana Roo" },
    { value: "san luis potosí", description: "San Luis Potosí" },
    { value: "sinaloa", description: "Sinaloa" },
    { value: "sonora", description: "Sonora" },
    { value: "tabasco", description: "Tabasco" },
    { value: "tamaulipas", description: "Tamaulipas" },
    { value: "tlaxcala", description: "Tlaxcala" },
    { value: "veracruz", description: "Veracruz" },
    { value: "yucatán", description: "Yucatán" },
    { value: "zacatecas", description: "Zacatecas" }
];

export const levelEducation = [
    { value: "primaria", description: "Primaria" },
    { value: "secundaria", description: "Secundaria" },
    { value: "bachillerato o preparatoria", description: "Bachillerato o preparatoria" },
    { value: "educación técnica o profesional", description: "Educación técnica" },
    { value: "licenciatura", description: "Licenciatura" },
    { value: "maestría", description: "Maestría" },
    { value: "doctorado", description: "Doctorado" },
];

export const languages = [
    { value: "ingles", description: "ingles" },
    { value: "frances", description: "frances" },
    { value: "japones", description: "japones" },
];

export const accounts = [
    { value: "9801 9878 0978 0919", description: "9801 9878 0978 0919" },
    { value: "9878 9889 0019 0918", description: "9878 9889 0019 0918" },
    { value: "9232 2200 1100 0010", description: "9232 2200 1100 0010" },
    { value: "9078 9890 0912 1230", description: "9078 9890 0912 1230" },
];

export const typeUserOptions = [
    { value: "director", description: "director" },
    { value: "subdirector", description: "subdirector" },
    { value: "administrativo", description: "administrativo" },
    { value: "desarrollador", description: "desarrollador" },
    { value: "control escolar", description: "control escolar" },
    { value: "coordinador", description: "coordinador" },
    { value: "cobranza", description: "cobranza" },
    { value: "ventas", description: "ventas" },
    { value: "profesor", description: "profesor" },
    { value: "estudiante", description: "estudiante" },
];

export const nivels = [
    { value: "nivel 1", description: "nivel 1" },
    { value: "nivel 2", description: "nivel 2" },
    { value: "nivel 3", description: "nivel 3" },
    { value: "nivel 4", description: "nivel 4" },
];

export const colors = [
    { name: 'red', clase: 'bg-red-600', selectedClass: 'ring-red-600 shadow-red-500' },
    { name: 'orange', clase: 'bg-orange-500', selectedClass: 'ring-orange-500 shadow-orange-500' },
    { name: 'yellow', clase: 'bg-yellow-400', selectedClass: 'ring-yellow-400 shadow-yellow-500' },
    { name: 'green', clase: 'bg-lime-600', selectedClass: 'ring-lime-600 shadow-lime-500' },
    { name: 'blue', clase: 'bg-sky-500', selectedClass: 'ring-sky-500 shadow-sky-500' },
    { name: 'purple', clase: 'bg-purple-500', selectedClass: 'ring-purple-500 shadow-purple-500' },
];


export const avatars = [
    { urlAvatar: "https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/mgdi1glpnoyebozm2ifj.png" },
    { urlAvatar: "https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/hbzdzch5ldxw6pszwmej.png" },
    { urlAvatar: "https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/hc0kpsmuj6iklivapeo9.png" },
    { urlAvatar: "https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/dxx2uay4ajrs6rt9w96x.png" },
    { urlAvatar: "https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/leyq6ryeeqavaewponfm.png" },
    { urlAvatar: "https://res.cloudinary.com/dax0v05jz/image/upload/v1708645791/uploads/xwqiz0lxnd0rvmhda68g.png" }
];

export const paymentStatus = [
    {
        "id": "allStatus",
        "description": "Todos los estados",
        "name": "Todos los pagos",
    },
    {
        "id": "validado",
        "description": "validado",
        "name": "Validado",
    },
    {
        "id": "pendiente",
        "description": "pendiente",
        "name": "Pendiente",
    },
    {
        "id": "rechazado",
        "description": "rechazado",
        "name": "Rechazado",
    },
];

export const paymentTypes = [
    {
        "id": "allTypes",
        "description": "Todos los tipos",
        "name": "Todos los pagos",
    },
    {
        "id": "suscripcion",
        "description": "suscripcion",
        "name": "Suscripcion",
    },
    {
        "id": "pre registro",
        "description": "pre registro",
        "name": "Pre registro",
    },
];

export const statusCourse = [
    {
        "value": "en curso",
        "name": "en curso",
        "description": "en curso"
    },
    {
        "value": "abierto",
        "name": "abierto",
        "description": "abierto"
    },
    {
        "value": "finalizado",
        "name": "finalizado",
        "description": "finalizado"
    },
    {
        "value": "completado",
        "name": "completado",
        "description": "completado"
    },
    {
        "value": "cancelado",
        "name": "cancelado",
        "description": "cancelado"
    },
];

export const statusStudents = [
    {
        "value": "activo",
        "name": "activo",
        "description": "activo"
    },
    {
        "value": "inactivo",
        "name": "inactivo",
        "description": "inactivo"
    },
    {
        "value": "suspendido",
        "name": "suspendido",
        "description": "suspendido"
    },
    {
        "value": "baja",
        "name": "baja",
        "description": "baja"
    }
];

export const usageRightsText = "";

export const optionsTypeUser = [
    {
      "value": "estudiante",
      "label": "estudiante"
    },
    {
      "value": "profesor",
      "label": "profesor"
    },
    {
      "value": "control escolar",
      "label": "control escolar"
    },
    {
      "value": "administrativo",
      "label": "administrativo"
    },
    {
      "value": "desarrollador",
      "label": "desarrollador"
    },
    {
      "value": "director",
      "label": "director"
    },
    {
      "value": "subdirector",
      "label": "subdirector"
    },
    {
      "value": "coordinador",
      "label": "coordinador"
    },
    {
      "value": "ventas",
      "label": "ventas"
    },
    {
      "value": "cobranza",
      "label": "cobranza"
    },
    {
      "value": "invitado",
      "label": "invitado"
    }
  ]
  