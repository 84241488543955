import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { resetEmailVerification } from '../../redux/reducers/preRegistration';

import logo from '../../static/image/logo.png';
import image from '../../static/image/9.png';

export const FormValidacionProceso = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { email } = useSelector((state) => state.preRegistration);



	useEffect(() => {
		if (!email) {
			navigate(`/pre-registro`)
		}
	});

	const handleContinue = () => {
		dispatch(resetEmailVerification())
		navigate(`/pre-registro`);
	}

	const renderStep = () => (
		<div
			className='w-full flex flex-col justify-center py-10 overflow-auto bg-white'
			style={{ height: "100dvh" }}
		>
			{/* Header form */}
			<div>
				<div className='flex justify-center items-center'>
					<div className='w-[45%] md:w-[40%]'>
						<img src={logo} alt="logo" />
					</div>
				</div>
				<div className='flex justify-center items-center mt-2'>
					<div className='w-10/12 mx-auto'>
						<h1 className='text-2xl font-bold text-gray-950  text-center'>
							Estamos validando tu pago
						</h1>
					</div>
				</div>
				<div className='flex justify-center items-center mt-4'>
					<div className='w-[85%] md:w-2/3'>
						<h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
							Cuando la vilidacion de tu pago este completada enviaremos un email a <strong>{email}</strong> con tus accesos.
						</h2>
					</div>
				</div>
			</div>

			<div>
				{/* Footer form */}
				<div className="flex w-full md:w-10/12 mx-auto justify-center mt-8">
					<div
						onClick={handleContinue}
						className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
						Continuar
						<span
							className='ml-3'
							aria-hidden="true"
						>&rarr;
						</span>
					</div>
				</div>
			</div>
		</div>
	)

	return (
		<div className='w-full flex'>
			<div className='w-full h-screen md:w-[35%]'>
				{renderStep()}
			</div>
			<div
				className='w-0 md:w-[65%] h-screen bg-cover'
				style={{ backgroundImage: `url(${image})` }}
			></div>
		</div>
	);
}



