import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loading } from '../../components/loading/Loading';
import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { capitalizarPalabras } from '../../common/upperCaseWord';
import { formatDateTime } from '../../common/formatDateText';

export const PaymentHistory = ({ action = false }) => {

    const { paymentsByUser, loading } = useSelector((state) => state.payment);

    return (
        <>
            {
                loading ? (
                    <Loading />
                ) : (
                    <>
                        {
                            paymentsByUser && paymentsByUser.length > 0
                                ? paymentsByUser.map(({ id, paymentType, validatedBy, status, amount, formatCreatedAt }) => (
                                    <div
                                        className='flex flex-col border-[1px] bg-white border-gray-200 rounded-lg p-4 my-4'
                                        key={id}
                                    >
                                        <div className='w-full border border-gray-200'>
                                            <PropertyListItem>
                                                <PropertyItem
                                                    title={`Ultima actualización`}
                                                    description={formatCreatedAt ? capitalizarPalabras(formatDateTime(formatCreatedAt)) : '-'}
                                                    positionTile='md:text-left'
                                                />
                                                <PropertyItem
                                                    title={`Estatus del pago`}
                                                    description={status ? capitalizarPalabras(status) : '-'}
                                                    positionTile='md:text-left'
                                                />
                                                <PropertyItem
                                                    title={`Monto del pago`}
                                                    description={amount ? `$ ${amount}` : `$ 0`}
                                                    positionTile='md:text-left'
                                                />
                                                <PropertyItem
                                                    title={`Tipo de pago`}
                                                    description={paymentType ? capitalizarPalabras(paymentType) : '-'}
                                                    positionTile='md:text-left'
                                                />
                                                <PropertyItem
                                                    title={`Pago validado por`}
                                                    description={validatedBy ? capitalizarPalabras(validatedBy) : '-'}
                                                    positionTile='md:text-left'
                                                />
                                            </PropertyListItem>
                                        </div>
                                        {
                                            action && (
                                                <div className='w-full '>
                                                    <div className='w-full flex justify-end mt-4'>
                                                        {
                                                            status === 'validado'
                                                                ? <Link
                                                                    className='rounded-lg transition py-2 px-3  font-semibold text-md text-white text-center bg-sky-600 hover:bg-sky-800'
                                                                    to={`/detalle-pago/${id}`}
                                                                >
                                                                    Detalle del pago
                                                                </Link>
                                                                : <Link
                                                                    className='rounded-lg transition py-2 px-3  font-semibold text-md text-white text-center bg-orange-500 hover:bg-orange-700'
                                                                    to={`/modificar-pago/${id}`}
                                                                >
                                                                    Modificar pago
                                                                </Link>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                ))
                                : <p className='p-4 text-gray-500'>No hay registro de pagos</p>
                        }
                    </>
                )

            }
        </>
    )
}
