import { Link } from "react-router-dom";
import { Wrapper } from "../../../../components/Wrapper";
import { formatDate } from "../../../../common/formatDateText";
import { capitalizarPalabras, firstCapitalLetter } from "../../../../common/upperCaseWord";
import { PostMenu } from "./PostMenu";

export const PostCard = ({
    id,
    userId,
    title,
    author,
    releaseDate,
    expirationDate,
    urlImg,
    message,
    url,
    typeUser,
}) => {
    const canView = ["director", "administrativo"];
    return (
        <Wrapper>
            <div className='p-4'>
                <div className='ml-1'>
                    <div className="flex">
                        <div>
                            <p className='text-[24px] font-bold uppercase break-words tracking-wide'>{title}</p>
                        </div>
                        <div className="flex justify-end flex-1">
                            {
                                canView.includes(typeUser) && (
                                    <PostMenu
                                        postId={id}
                                        userId={userId}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <p className='flex text-indigo-500 text-[15px] capitalize font-semibold'>{author}</p>
                        <p className='text-gray-400 text-[13px] font-normal'>{capitalizarPalabras(formatDate(releaseDate))}</p>
                        {
                            canView.includes(typeUser) && (
                                <p className='text-red-500 text-[13px] font-normal'>{`Esta publicacion termina: ${capitalizarPalabras(formatDate(expirationDate))}`}</p>
                            )
                        }
                    </div>
                </div>
                {
                    urlImg && (
                        <div className='flex justify-center items-center mt-6'>
                            <img
                                className='max-w-[90%] w-auto h-auto rounded-md shadow-md shadow-indigo-50'
                                src={urlImg} alt="imagen"
                            />
                        </div>

                    )
                }
                {
                    message && (
                        <div className='w-full flex flex-col mt-4'>
                            <p className='text-[1rem] break-words'>{firstCapitalLetter(message)}</p>
                        </div>
                    )
                }
                {
                    url && (
                        <div className='w-full flex justify-center items-center mt-6 bg-indigo-50 p-6 rounded-md border-indigo-600'>
                            <Link to={url} className='text-blue-600'>{url}</Link>
                        </div>
                    )
                }
            </div>
        </Wrapper>
    )
}
