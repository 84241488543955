import { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip, Zoom } from '@mui/material';

export const ComboBox = ({ filterData, query, setQuery, selected, setSelected, placeholder = '', property }) => {

    const handleChange = (value) => {
        setSelected({ ...selected, [property]: value });
    };

    const clearSelection = () => {
        setSelected({ ...selected, [property]: '' }); // Elimina la selección
        setQuery(''); // Limpia el query
    };

    const hasSelection = !!selected?.[property]; // Verificar si hay selección

    return (
        <div className="">
            <Combobox value={selected?.[property]} onChange={(value) => handleChange(value)}>
                <div className="relative mt-1">
                    <div className="relative w-full">
                        <Combobox.Input
                            className="w-full rounded-md border-none py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:bg-indigo-50 sm:text-sm sm:leading-6 capitalize"
                            displayValue={(item) => item?.description || ''}
                            onChange={(event) => setQuery(event.target.value)}
                            placeholder={placeholder}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
                            {hasSelection && (
                                <Tooltip title="Eliminar selecion" TransitionComponent={Zoom} placement='top'>
                                    <button
                                        type="button"
                                        onClick={clearSelection}
                                        className="focus:outline-none"
                                    >
                                        <XCircleIcon
                                            className="h-5 w-5 text-gray-300 hover:text-red-500"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </Tooltip>// Solo mostrar el botón de limpiar si hay selección
                            )}
                            <Combobox.Button className="flex items-center">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </Combobox.Button>
                        </div>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm z-40">
                            {filterData.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    No se encontró.
                                </div>
                            ) : (
                                filterData.map((item, index) => (
                                    <Combobox.Option
                                        key={`${item.description}-${index}`}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 uppercase ${active ? 'bg-indigo-600 text-white' : 'text-gray-900'}`
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={`block truncate uppercase ${selected ? 'font-medium' : 'font-normal'}`}>
                                                    {item.description}
                                                </span>
                                                {selected && (
                                                    <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-indigo-600'}`}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
};
