import { Wrapper } from "../../../components/Wrapper"

export const SkeletonPost = () => {
    return (
        <div>
            <Wrapper>
                <div className='p-4'>
                    <div className='w-full bg-gray-400 h-20 rounded-md animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-96 rounded-md mt-6 animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-32 rounded-md mt-6 animate-blink'>
                    </div>
                </div>
            </Wrapper>
            <Wrapper>
                <div className='p-4'>
                    <div className='w-full bg-gray-400 h-20 rounded-md animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-96 rounded-md mt-6 animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-32 rounded-md mt-6 animate-blink'>
                    </div>
                </div>
            </Wrapper>
            <Wrapper>
                <div className='p-4'>
                    <div className='w-full bg-gray-400 h-20 rounded-md animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-96 rounded-md mt-6 animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-32 rounded-md mt-6 animate-blink'>
                    </div>
                </div>
            </Wrapper>
            <Wrapper>
                <div className='p-4'>
                    <div className='w-full bg-gray-400 h-20 rounded-md animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-96 rounded-md mt-6 animate-blink'>
                    </div>
                    <div className='w-full bg-gray-400 h-32 rounded-md mt-6 animate-blink'>
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}
