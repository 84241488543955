export const isValidPhoneNumber = (phoneNumber) => {
    // Eliminar todo lo que no sea un dígito
    const rawDigits = phoneNumber.replace(/\D/g, '');

    // Verificar que tenga exactamente 10 dígitos
    if (rawDigits.length !== 10) {
        return false;
    }

    // Verificar si el formato es correcto (999) 999 9999 y que no haya caracteres incompletos
    const validFormat = /^\(\d{3}\) \d{3} \d{4}$/;

    // Si pasa el formato y tiene exactamente 10 dígitos, es válido
    return validFormat.test(phoneNumber);
}
