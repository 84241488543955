import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';

import { formatDate, validateSubscription } from '../../../common/formatDateText';
import { urlFlag } from '../../../common/urlBase';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const CardCourseDisplay = ({ id, headerImage, language, level, color, path, status, teacher, fromDate, toDate, days, hours, action = false }) => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const { paymentDeadlineDate } = user;

    const handleGoToCourse = (idCourse) => () => {
        navigate(`/curso/${idCourse}`);
    }

    return (
        <div className='w-full mx-auto flex flex-col shadow-md shadow-indigo-950/20 rounded-md mt-8'>
            <div className="overflow-hidden">
                {
                    headerImage !== undefined
                        ? (
                            <div className="">
                                <img className="w-full rounded-t-md object-contain hover:object-scale-down" src={headerImage} alt="header" />
                            </div>
                        )
                        : null
                }

            </div>
            <div className='w-full flex flex-col p-4'>
                <div className="flex justify-between mb-4">
                    <div>
                        <p className='text-md text-gray-900 font-bold tracking-wide uppercase'>{language ? `Curso de ${language}` : 'No hay idioma selecionado'}</p>
                        <div className="flex items-center">
                            <span className={classNames(
                                color,
                                'inline-block w-[14px] h-[14px] rounded-lg mr-2'
                            )}></span>
                            <p className='text-[14px] text-indigo-700 font-bold tracking-wide uppercase'>{level}</p>
                        </div>
                    </div>
                    <div>
                        {path
                            ? (
                                <img className="w-[32px]" src={`${urlFlag}${path}`} alt="flag" />
                            )
                            : null}
                    </div>
                </div>

                <div className="flex justify-end">
                    <div
                        className={classNames(
                            status === 'abierto'
                                ? 'text-sky-700 ring-sky-700 shadow-sky-700'
                                : status === 'en curso' ? 'text-lime-600 ring-lime-600 shadow-lime-600'
                                    : status === 'completado' ? 'text-indigo-600 ring-indigo-600 shadow-indigo-600'
                                        : status === 'cancelado' ? 'text-gray-600 ring-gray-600 shadow-gray-600'
                                            : status === 'finalizado' ? 'text-red-500 ring-red-500 shadow-red-500' : null,
                            'border-none ring-2  shadow-sm py-0 px-1 rounded-sm uppercase font-bold'
                        )}>
                        <p className="text-[.8rem]">{status}</p>
                    </div>
                </div>

                <div className="w-full grid grid-cols-2 gap-4 mt-6">
                    <div className="w-full flex flex-col justify-center items-center">
                        <p className="font-normal text-sm text-gray-600 leading-6">Profesor del curso</p>
                        <p className="text-slate-800 font-semibold text-md tracking-wide capitalize">{teacher !== '' ? teacher : '-'}</p>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center">
                        <p className="font-normal text-sm text-gray-600 leading-6">Horario</p>
                        <p className="text-slate-800 font-semibold text-md tracking-wide">{
                            hours !== undefined
                                ? `${hours.length > 0 ? hours[0]?.time : ''} - ${hours.length > 0 ? hours[hours?.length - 1]?.time : ''}`
                                : 'No hay tiempo selecionado'
                        }</p>
                    </div>
                </div>
                <div className="w-full grid grid-cols-2 gap-4 mt-6">
                    <div className='w-full flex flex-col justify-start items-center'>
                        <p className="font-normal text-sm text-gray-600 leading-6">Fecha de inicio de curso</p>
                        <p className="text-slate-800 font-semibold text-md tracking-wide capitalize text-center">{fromDate !== '' ? formatDate(fromDate) : '-'}</p>
                    </div>
                    <div className='w-full flex flex-col justify-start items-center'>
                        <p className="font-normal text-sm text-gray-600 leading-6">Fecha de fin de curso</p>
                        <p className="text-slate-800 font-semibold text-md tracking-wide capitalize text-center">{toDate !== '' ? formatDate(toDate) : '-'}</p>
                    </div>
                </div>
                <div className="w-full grid grid-cols-1 gap-4 mt-6">
                    <div className="w-full min-h-[130px]">
                        <p className="font-normal text-sm text-gray-600 leading-6 mb-4">Dias de clase</p>
                        <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-4">
                            {days !== '' ? days?.map(day => <div key={day.id} className="bg-slate-100 text-slate-800 font-semibold text-md text-center px-2 tracking-wide capitalize rounded-sm lg:rounded-md">{day.day}</div>) : 'Hay dias selecionados'}
                        </div>
                    </div>
                </div>
                {
                    action && (
                        <button
                            onClick={handleGoToCourse(id)}
                            disabled={!validateSubscription(paymentDeadlineDate)}
                            className="w-full py-2 text-white font-semibold rounded-md bg-indigo-600 focus:bg-indigo-700 disabled:bg-gray-400 disabled:cursor-not-allowed hover:shadow-md transition duration-100 ease-in-out mt-2"
                        >Ir a curso</button>
                    )
                }

            </div>
        </div>
    )
}
