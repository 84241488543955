import {
	preRegistrationStart,
	preRegistrationSuccess,
	preRegistrationFailure,
	emailVerificationStart,
	emailVerificationSuccess,
	emailVerificationFailure,
	resetEmailVerification,
	validatePaymentStart,
	validatePaymentSuccess,
	validatePaymentFailure,
	getAllPreRegisterStart,
	getAllPreRegisterSuccess,
	getAllPreRegisterFailure,
	getSelectedPreRegisterStart,
	getSelectedPreRegisterSuccess,
	getSelectedPreRegisterFailure,
	cleanSelectedPreRegister,
} from '../reducers/preRegistration';

import axios from '../../api/axios';
import { toast } from 'react-hot-toast';

export const emailVerification = (email) => async (dispatch) => {
	try {
		dispatch(emailVerificationStart(email));
		// Realiza la llamada al servidor para email existente
		const response = await axios.get(`/preRegister/emailVerification/${email}`);

		// Verifica si la respuesta es exitosa
		dispatch(emailVerificationSuccess(response));
		if (!response.data.emailExist) {
			dispatch(resetEmailVerification())
		}

		return {
			status: response?.status || null,
			message: response?.data.message
		};

	} catch (error) {
		dispatch(emailVerificationFailure());
		return {
			status: error.response.status || null,
			message: error.response.data.message
		};
	}
};

export const resetPreRegisterstate = () => async (dispatch) => {
	resetEmailVerification();
};

export const registerPreRegitration = ({
	firstName,
	secondName,
	lastName,
	secondSurname,
	email,
	phone,
	dateBirth,
	location,
	education,
	language,
	usageRights,
	rfc,
}) => async (dispatch) => {
	try {

		dispatch(preRegistrationStart());
		const response = await axios.post(`/preRegister`,
			{
				firstName,
				secondName,
				lastName,
				secondSurname,
				email,
				phone,
				dateBirth,
				location,
				education,
				language,
				usageRights,
				rfc
			});
		dispatch(preRegistrationSuccess(response));
		return {
			status: response.status,
			message: response.data.message
		};

	} catch (error) {
		dispatch(preRegistrationFailure(error.response));
		return {
			status: error.response.data.status,
			message: error.response.data.message
		};
	}
};

// payment voucher
export const validatePaymentVoucher = ({
	amount,
	account,
	coordinador,
	file,
	email,
	id
}) => async (dispatch) => {
	dispatch(validatePaymentStart());
	try {
		const formData = new FormData();
		formData.append('file', file);
		formData.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
		formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
		formData.append('upload_preset', 'fca-intranet');

		const res = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, formData);
		if (res.status === 200) {
			const response = await axios.post('/preRegister/validatePaymentVoucher', {
				urlName: res.data.secure_url,
				publicId: res.data.public_id,
				account,
				coordinador,
				email,
				id,
				amount: parseFloat(amount)
			});
			if (response.status === 200) {
				dispatch(validatePaymentSuccess(response));
				return {
					status: response.status,
					message: response.data.message
				};
			}
		}

	} catch (error) {
		dispatch(validatePaymentFailure());
		toast.error(error.response.data.message)
	}
};

export const getAllPreRegister = ({ id, roles, startDate, endDate }) => async (dispatch) => {
	dispatch(getAllPreRegisterStart());
	try {

		const response = await axios.get(`/preRegister/allPreRegister/${id}/${roles}/${startDate}/${endDate}`,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			});

		dispatch(getAllPreRegisterSuccess(response.data))

	} catch (error) {
		dispatch(getAllPreRegisterFailure());
	}
};

export const getSelectedPreRegister = (preRegisterId) => async (dispatch) => {
	dispatch(getSelectedPreRegisterStart());
	try {

		const response = await axios.get(`/preRegister/getPreRegisterById/${preRegisterId}`, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		dispatch(getSelectedPreRegisterSuccess(response.data))

	} catch (error) {
		console.log(error.response.data.message)
		dispatch(getSelectedPreRegisterFailure());
	}
};

export const cleanActionSelectedPreRegister = () => async (dispatch) => {
	dispatch(cleanSelectedPreRegister());
};


export const validateCandidate = (payload) => async (dispatch) => {
	const { coordinador, createdBy, paymentDeadlineDate, idPreregister, idCourses, message, amount, account, subscriptionPayment } = payload;

	try {

		const response = await axios.post(`/preRegister/validateCandidate`, {
			coordinador, createdBy, paymentDeadlineDate, idCourses, idPreregister, message, amount, account, subscriptionPayment
		}, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return {
			status: response.status || null,
			message: response.data.message
		};
	} catch (error) {
		return {
			status: error.response.status || null,
			message: error.response.data.message
		};
	}
};

export const rejectPreRegistration = (payload) => async (dispatch) => {

	const { preRegisterId, email, publicId, message } = payload;
	dispatch(getSelectedPreRegisterStart());
	try {
		const response = await axios.put(`/preRegister/rejectPreRegister/${preRegisterId}`, { email, publicId, message });
		dispatch(getSelectedPreRegisterSuccess(response.data))
		if (response?.status === 200) {
			return {
				status: response?.status || null,
				message: response?.data.message
			};
		}

	} catch (error) {
		dispatch(getSelectedPreRegisterFailure());
		return {
			status: error.response.status || null,
			message: error.response.data.message
		};
	}
}

export const requestChangeCoordinador = (preRegisterId) => async (dispatch) => {
	try {
		const response = await axios.get(`/preRegister/requestUpdateCoordinator/${preRegisterId}`);
		if (response?.status === 200) {
			return {
				status: response?.status || null,
				message: response?.data.message
			};
		}
	} catch (error) {
		return {
			status: error.response.status || null,
			message: error.response.data.message
		};
	}
}
export const updatePreRegistration = ({ coordinadorId, preRegisterId, userId }) => async (dispatch) => {
	try {
		const response = await axios.put(`/preRegister/updateCoordinator/${preRegisterId}`, { coordinadorId, userId });
		if (response?.status === 200) {
			return {
				status: response?.status || null,
				message: response?.data.message
			};
		}
	} catch (error) {
		return {
			status: error.response.status || null,
			message: error.response.data.message
		};
	}
}


