import React, { useEffect, useState } from 'react'
import { DrawerCustom } from '../../components/drawer/Drawer'
import { Heading } from '../../components/Heading';
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';
import { ComboBox } from '../../components/comboBox/ComboBox';
import { useDispatch, useSelector } from 'react-redux';
import { optionsAllCourseIdentifier, optionsAllTeachers, optionsLanguages, optionsLevels } from '../../redux/actions/options';
import { statusCourse } from '../../static/data';

export const DrawerFiltersCourse = ({
    isOpenDrawerFilters,
    toggleDrawerFilters,
    formData,
    setFormData,
    handleApllyFilters,
    handleRemoveFilter
}) => {
    const dispatch = useDispatch();
    const { teachers, levels, languages, identifiers } = useSelector((state) => state.options);
    const [startDate, setStartDate] = useState(formData.fromDate ? formData.fromDate : '');
    const [endDate, setEndDate] = useState(formData.toDate ? formData.toDate : '');
    const [findCourseIdentifier, setFindCourseIdenfier] = useState('');
    const [findTeacher, setFindTeacher] = useState('');
    const [findLanguage, setFindLanguage] = useState('');
    const [findLevel, setFindLevel] = useState('');
    const [findStatu, setFindStatu] = useState('');

    useEffect(() => {
        dispatch(optionsAllTeachers());
        dispatch(optionsLevels());
        dispatch(optionsLanguages());
        dispatch(optionsAllCourseIdentifier());
    }, [dispatch]);

    useEffect(() => {
        setFormData({
            ...formData,
            fromDate: startDate,
        })
    }, [startDate]);

    useEffect(() => {
        setFormData({
            ...formData,
            toDate: endDate,
        })
    }, [endDate]);

    const handlePreparePayload = () => {
        handleApllyFilters({
            userId: formData.userId,
            roles: formData.roles,
            fromDate: startDate ? startDate : '',
            toDate: endDate ? endDate : '',
            identifier: formData.identifier ? formData.identifier.name : '',
            teacher: formData.teacher ? formData.teacher._id : '',
            level: formData.level ? formData.level.value : '',
            status: formData.status ? formData.status.description : '',
            language: formData.language ? formData.language.value : '',
        });
    }

    const handleResetFilters = () => {
        setStartDate(null)
        setEndDate(null)
        handleRemoveFilter();
    }


    const filteredCourseIdentifier = findCourseIdentifier === ''
        ? identifiers
        : identifiers.filter((identifier) =>
            identifier.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findCourseIdentifier.toLowerCase().replace(/\s+/g, ''))
        );

    const filteredTeachers = findTeacher === ''
        ? teachers
        : teachers.filter((teacher) =>
            teacher.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findTeacher.toLowerCase().replace(/\s+/g, ''))
        );

    const filteredLanguages = findLanguage === ''
        ? languages
        : languages.filter((language) =>
            language.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findLanguage.toLowerCase().replace(/\s+/g, ''))
        );

    const filteredLevels = findLevel === ''
        ? levels
        : levels.filter((level) =>
            level.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findLevel.toLowerCase().replace(/\s+/g, ''))
        );

    const filteredStatus = findStatu === ''
        ? statusCourse
        : statusCourse.filter((statu) =>
            statu.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findStatu.toLowerCase().replace(/\s+/g, ''))
        );

    return (
        <DrawerCustom
            isOpen={isOpenDrawerFilters}
            toggleDrawer={toggleDrawerFilters}
            direction='left'
        >
            <>
                <div className='flex flex-1 flex-col w-full p-4'>
                    {/* headerr */}
                    <div className='w-full'>
                        <Heading
                            title={"Filtros de la lista de cursos"}
                            subtitle={"Selecciona los filtros aplicar para a la lista de los cursos."}
                            center={false}
                        />
                    </div>
                    {/* body */}
                    <div className='w-full mt-8 p-2'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                            <div className='w-full'>
                                <InputDateWithIcon
                                    id={'startDay'}
                                    onChange={setStartDate}
                                    value={startDate}
                                    label={'Fecha de inicio del curso'}
                                    placeHolder={'Fecha de inicio'}

                                />
                            </div>
                            <div className='w-full'>
                                <InputDateWithIcon
                                    id={'endDay'}
                                    onChange={setEndDate}
                                    value={endDate}
                                    label={'Fecha de final del curso'}
                                    placeHolder={'Fecha de final'}

                                />
                            </div>
                            <div className='w-full'>
                                <h3 className="text-md mb-4 font-semibold text-gray-900">Identificador</h3>
                                <ComboBox
                                    filterData={filteredCourseIdentifier}
                                    query={findCourseIdentifier}
                                    setQuery={setFindCourseIdenfier}
                                    selected={formData}
                                    setSelected={setFormData}
                                    placeholder='Identificador'
                                    property='identifier'
                                />
                            </div>
                            <div className='w-full'>
                                <h3 className="text-md mb-4 font-semibold text-gray-900">Profesor</h3>
                                <div className='w-full'>
                                    <ComboBox
                                        filterData={filteredTeachers}
                                        query={findTeacher}
                                        setQuery={setFindTeacher}
                                        selected={formData}
                                        setSelected={setFormData}
                                        placeholder='Profesor'
                                        property='teacher'
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h3 className="text-md mb-4 font-semibold text-gray-900">Idioma</h3>
                                <div className='w-full'>
                                    <ComboBox
                                        filterData={filteredLanguages}
                                        query={findLanguage}
                                        setQuery={setFindLanguage}
                                        selected={formData}
                                        setSelected={setFormData}
                                        placeholder='Idioma'
                                        property='language'
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h3 className="text-md mb-4 font-semibold text-gray-900">Nivel</h3>
                                <div className='w-full'>
                                    <ComboBox
                                        filterData={filteredLevels}
                                        query={findLevel}
                                        setQuery={setFindLevel}
                                        selected={formData}
                                        setSelected={setFormData}
                                        placeholder='Nivel'
                                        property='level'
                                    />
                                </div>
                            </div>
                            <div className='w-full'>
                                <h3 className="text-md mb-4 font-semibold text-gray-900">Estado</h3>
                                <div className='w-full'>
                                    <ComboBox
                                        filterData={filteredStatus}
                                        query={findStatu}
                                        setQuery={setFindStatu}
                                        selected={formData}
                                        setSelected={setFormData}
                                        placeholder='Estatus de curso'
                                        property='status'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* footer */}
                <div className='flex w-full justify-end p-4 gap-6 border-t-[.5px] border-indigo-600/10'>
                    <button
                        type='button'
                        onClick={() => handleResetFilters()}
                        className='rounded-md font-semibold text-md text-gray-600 shadow-sm shadow-gray-200 bg-gray-200 hover:bg-gray-300 hover:shadow-gray-300 px-4 py-2.5'
                    >{"Borrar Filtros"}</button>
                    <button
                        type='button'
                        onClick={() => toggleDrawerFilters()}
                        className='rounded-md font-semibold text-md text-white shadow-sm shadow-red-500 bg-red-500 hover:bg-red-700 hover:shadow-red-700  px-4 py-2.5'
                    >{"Cancelar"}</button>
                    <button
                        type='button'
                        onClick={() => handlePreparePayload()}
                        className='rounded-md font-semibold text-md text-white shadow-sm shadow-indigo-600 bg-indigo-600 hover:bg-indigo-800 hover:shadow-indigo-800 px-4 py-2.5'
                    >{"Aplicar Filtros"}</button>
                </div>
            </>
        </DrawerCustom>
    )
}
