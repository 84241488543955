import React, { useEffect, useState } from 'react'
import { DrawerCustom } from '../../components/drawer/Drawer'
import { Heading } from '../../components/Heading';
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';
import { ComboBox } from '../../components/comboBox/ComboBox';
import { paymentStatus, paymentTypes } from '../../static/data';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { optionsAllAccountsBank } from '../../redux/actions/options';
import { InputAccountBankMini } from '../../components/inputAccountBank/InputAccountBankMini';

export const DrawerFiltersPaymets = ({
    isOpenDrawerFilters,
    toggleDrawerFilters,
    formData,
    setFormData,
    handleApllyFilters,
    handleRemoveFilter
}) => {
    const dispatch = useDispatch();
    const { accountsBank } = useSelector((state) => state.options);
    const [findPaymentStatus, setFindPaymentStatus] = useState('');
    const [findPaymentTypes, setFindPaymentTypes] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        dispatch(optionsAllAccountsBank());
    }, [dispatch]);

    const filteredPaymentStatus = findPaymentStatus === ''
        ? paymentStatus
        : paymentStatus.filter((paymentStatu) =>
            paymentStatu.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findPaymentStatus.toLowerCase().replace(/\s+/g, ''))
        );

    const filteredPaymentTypes = findPaymentTypes === ''
        ? paymentTypes
        : paymentTypes.filter((paymentType) =>
            paymentType.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findPaymentTypes.toLowerCase().replace(/\s+/g, ''))
        );

    const validateFileds = () => {
        if (!startDate || !endDate) {
            toast.error('Seleciona un intervalo de fechas.')
            return false;
        }
        if (startDate.getTime() > endDate.getTime()) {
            toast.error('La fecha de inicio debe ser menor a la fecha final.')
            return false;
        }
        if (startDate.getTime() === endDate.getTime()) {
            toast.error('Las fechas no pueden ser iguales.')
            return false;
        }
        if (!startDate && endDate) {

            toast.error('Debes seleccionar una fecha de inicio.')
            return false;
        }
        if (startDate && !endDate) {
            toast.error('Debes seleccionar una fecha final.')
            return false;
        }
        return true;
    }

    const handlePreparePayload = () => {
        const validate = validateFileds();
        if (validate) {
            handleApllyFilters({
                status: formData.status.description ? formData.status.description === "Todos los estados" ? 'allStatus' : formData.status.description : 'allStatus',
                paymentType: formData.paymentType.description ? formData.paymentType.description === "Todos los tipos" ? 'allTypes' : formData.paymentType.description : 'allTypes',
                startDate: startDate,
                endDate: endDate,
                accountNumber: formData.accountNumber?.description ? formData.accountNumber.description : 'allAccountNumbers',
            });
        }
    }

    const handleResetFilters = () => {
        setStartDate(null)
        setEndDate(null)
        handleRemoveFilter();
    }

    return (
        <DrawerCustom
            isOpen={isOpenDrawerFilters}
            toggleDrawer={toggleDrawerFilters}
            direction='left'
        >
            <>
                <div className='flex flex-1 flex-col w-full p-4'>
                    {/* headerr */}
                    <div className='w-full'>
                        <Heading
                            title={"Filtros de la tabla de pagos"}
                            subtitle={"Selecciona los filtros aplicar para tabla de pagos para tus pagos."}
                            center={false}
                        />
                    </div>
                    {/* body */}
                    <div className='w-full mt-8 p-2'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <div className='w-full'>
                                <InputDateWithIcon
                                    id={'startDay'}
                                    onChange={setStartDate}
                                    value={startDate}
                                    label={'Fecha de inicio'}
                                    placeHolder={'Fecha de inicio'}

                                />
                            </div>
                            <div className='w-full'>

                                <InputDateWithIcon
                                    id={'endDay'}
                                    onChange={setEndDate}
                                    value={endDate}
                                    label={'Fecha de final'}
                                    placeHolder={'Fecha de final'}

                                />
                            </div>
                            <div className='w-full'>
                                <label
                                    htmlFor={""}
                                    className="block text-md mb-2 font-medium leading-6 text-gray-900"
                                >
                                    {"Estatus"}
                                </label>
                                <ComboBox
                                    filterData={filteredPaymentStatus}
                                    query={findPaymentStatus}
                                    setQuery={setFindPaymentStatus}
                                    selected={formData}
                                    setSelected={setFormData}
                                    placeholder='Estatus del pago'
                                    property='status'
                                />
                            </div>
                            <div className='w-full'>
                                <label
                                    htmlFor={""}
                                    className="block text-md mb-2 font-medium leading-6 text-gray-900"
                                >
                                    {"Tipo de pago"}
                                </label>
                                <ComboBox
                                    filterData={filteredPaymentTypes}
                                    query={findPaymentTypes}
                                    setQuery={setFindPaymentTypes}
                                    selected={formData}
                                    setSelected={setFormData}
                                    placeholder='Tipo de pago'
                                    property='paymentType'
                                />
                            </div>
                        </div>

                        <div className='w-full mt-6'>
                            <label
                                htmlFor={""}
                                className="block text-md mb-2 font-medium leading-6 text-gray-900"
                            >
                                {"Cuenta bancaria"}
                            </label>
                            <InputAccountBankMini
                                accountsBank={accountsBank}
                                accountSelected={formData}
                                setAccountSelected={setFormData}
                            />
                        </div>
                    </div>
                </div>
                {/* footer */}
                <div className='flex w-full justify-end p-4 gap-6 border-t-[.5px] border-indigo-600/10'>
                    <button
                        type='button'
                        onClick={() => handleResetFilters()}
                        className='rounded-md font-semibold text-md text-gray-600 shadow-sm shadow-gray-200 bg-gray-200 hover:bg-gray-300 hover:shadow-gray-300 px-4 py-2.5'
                    >{"Borrar Filtros"}</button>
                    <button
                        type='button'
                        onClick={() => toggleDrawerFilters()}
                        className='rounded-md font-semibold text-md text-white shadow-sm shadow-red-500 bg-red-500 hover:bg-red-700 hover:shadow-red-700  px-4 py-2.5'
                    >{"Cancelar"}</button>
                    <button
                        type='button'
                        onClick={() => handlePreparePayload()}
                        className='rounded-md font-semibold text-md text-white shadow-sm shadow-indigo-600 bg-indigo-600 hover:bg-indigo-800 hover:shadow-indigo-800 px-4 py-2.5'
                    >{"Aplicar Filtros"}</button>
                </div>
            </>
        </DrawerCustom>
    )
}
