import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { formatDate, isDateGreaterThanTarget } from '../../common/formatDateText';
import { optionsAllAccountsBank, optionsAllCourseListOpenByIdUser } from '../../redux/actions/options';
import { acceptedPayment, getPaymentById } from '../../redux/actions/payment';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { Title } from '../../components/Title';
import { ImageZoom } from '../../components/imageZoom/ImageZoom';
import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { capitalizarPalabras } from '../../common/upperCaseWord';
import { InputText } from '../../components/inputs/InputText';
import { InputDate } from '../../components/inputDate/InputDate';
import { ConfirmationValidatePaymentModal } from './ConfirmationValidatePaymentModal';
import { ModalDeclinePayment } from './ModalDeclinePayment';

import { urlFlag } from '../../common/urlBase';

export const PaymentValidation = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user: userProfile } = useSelector((state) => state.user);
    const { _id: userId } = userProfile;
    const { paymentSelected } = useSelector((state) => state.payment);
    const { accountsBank, coursesListByIdUser } = useSelector((state) => state.options);
    const cancelCreateCourseRef = useRef(null);
    const [openDeclinePaymentModal, setOpenDeclinePaymentModal] = useState(false);
    const [openConfirmationValidationPaymentModal, setOpenConfirmationValidationPaymentModal] = useState(false);
    const [selectExpireDate, setSelectExpireDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        paymentId: id,
        validatedBy: '',
        account: '',
        accountNumber: '',
        paymentDeadlineDate: null,
        message: '',
        amount: '0',
    });

    useEffect(() => {
        dispatch(getPaymentById(id))
    }, [])

    useEffect(() => {
        dispatch(optionsAllAccountsBank());
    }, []);

    useEffect(() => {
        dispatch(optionsAllCourseListOpenByIdUser(user?._id));
    }, [paymentSelected]);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
        }));
    }, [accountsBank]);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
            amount: amount || '0',
        }));
    }, [paymentSelected]);

    useEffect(() => {
        if (isDateGreaterThanTarget(selectExpireDate, user?.paymentDeadlineDate)) {
            setFormData((prevData) => ({
                ...prevData,
                paymentDeadlineDate: selectExpireDate,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                paymentDeadlineDate: '',
            }));
            if (selectExpireDate !== null) {
                toast.error('La nueva fecha de vencimiento debe ser mayor a la fecha actual de vencimiento.');
            }
        }
    }, [selectExpireDate])

    const getIdAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.value;
    }

    const getNumberAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.description;
    }

    const { _id: paymentId, user, account, amount, paymentType, status, urlName, createdAt, subscriptionPayment } = paymentSelected;

    const handleCancelValidationPayment = async () => {
        setOpenDeclinePaymentModal(true);
    }

    const handleValidationPayment = async () => {
        if (!formData.paymentDeadlineDate) {
            toast.error('La nueva fecha de vencimiento debe ser mayor a la fecha actual de vencimiento.');
            return;
        } else {
            setOpenConfirmationValidationPaymentModal(true);
        }
    }

    const comfirmationValidationPayment = async () => {
        const updateState = {
            ...formData,
            paymentDeadlineDate: formData.paymentDeadlineDate,
            email: paymentSelected.user?.email,
            validatedBy: userId,
            updatedBy: userId,
            userId: paymentSelected.user?._id
        }
        dispatch(acceptedPayment(updateState))
            .then((result) => {
                if (result.status === 200) {
                    toast.success(result.message);
                    navigate('/pagos');
                    setLoading(false);
                } else {
                    toast.error(result.message);
                    setLoading(false);
                }
            });
    }

    return (
        <ContainerFull>
            <Heading
                title={`Validar pago de suscripcion`}
                subtitle={`Se debe validar el pago de la suscripcion, es importate revisar que el pago sea correcto y asignar una nueva fecha de vencimiento.`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Informacion del cadidato' />
                        <PropertyListItem>
                            <PropertyItem
                                title={`Matricula`}
                                description={`${user?.matricula}`}
                            />
                            <PropertyItem
                                title={`Nombre completo`}
                                description={`${capitalizarPalabras(user?.firstName)} ${capitalizarPalabras(user?.lastName)}`}
                            />
                            <PropertyItem
                                title={`Email`}
                                description={user?.email}
                            />
                            <PropertyItem
                                title={`Fecha del pago`}
                                description={capitalizarPalabras(formatDate(createdAt))}
                            />
                            <PropertyItem
                                title={`Fecha de vencimiento`}
                                description={capitalizarPalabras(formatDate(user?.paymentDeadlineDate))}
                            />
                            <PropertyItem
                                title={`Tipo de pago`}
                                description={capitalizarPalabras(paymentType)}
                            />
                            <PropertyItem
                                title={`Monto de la suscripcion`}
                                description={user?.subscriptionPayment ? `$ ${user?.subscriptionPayment}` : `No esta definido`}
                            />
                            <PropertyItem
                                title={`Estatus`}
                                description={capitalizarPalabras(status)}
                            />
                        </PropertyListItem>
                        <hr className='my-8' />
                        <Title title='Cursos activos del estudiante' />
                        {
                            coursesListByIdUser && (
                                coursesListByIdUser.map(({ id, language, days, hours, level, path, teacher }) => (
                                    <div key={id} className='flex border border-gray-200 rounded-lg p-4 mb-4'>
                                        <div className='flex flex-row justify-start items-center gap-4'>
                                            <div className='flex flex-col'>
                                                <div className='flex items-center'>
                                                    <img className="w-[24px] mr-2" src={`${urlFlag}${path}`} alt="flag" />
                                                    <p className='text-[16px] font-semibold text-gray-950'>{`Curso de ${capitalizarPalabras(language)} - ${capitalizarPalabras(level)}`}</p>
                                                </div>
                                                <div className='flex mt-4'>
                                                    {
                                                        <p className='flex mr-2 text-[12px] capitalize font-semibold rounded-sm bg-gray-100 px-4'>{`Maestro: ${teacher}`}</p>
                                                    }
                                                </div>
                                                <div className='flex mt-4'>
                                                    {
                                                        hours?.length && (
                                                            <p className='flex mr-2 text-[12px] capitalize font-semibold rounded-sm bg-gray-100 px-4'>{`Horario: ${hours[0]?.time} - ${hours[hours.length - 1]?.time}`}</p>
                                                        )
                                                    }

                                                </div>
                                                <div className='grid grid-cols-3 gap-2 md:flex mt-4'>
                                                    {
                                                        days?.length && days.map(({ day }) => (
                                                            <p key={day} className='flex flex-row text-[12px] capitalize font-semibold rounded-sm bg-gray-100 px-4'>{day}</p>
                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                        {
                            coursesListByIdUser?.length === 0 && (
                                <p className='text-gray-400 p-4'>El estudiante no esta registrado en ningun curso</p>
                            )
                        }
                    </div>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Comprobante de pago' center={true} />
                        <div className='w-full flex flex-col justify-center items-center'>
                            <ImageZoom imageUrl={urlName} />
                        </div>
                        <div className='w-[70%] mx-auto flex flex-col mt-8'>
                            <div className='mb-4'>
                                <InputText
                                    id={'account'}
                                    name={'account'}
                                    type={'text'}
                                    label={'Cuenta de deposito'}
                                    value={formData.accountNumber}
                                    placeholder={''}
                                    disabled={true}
                                />
                            </div>
                            <div className='mb-4'>
                                <InputText
                                    id={'amount'}
                                    name={'amount'}
                                    type={'text'}
                                    label={'Cantidad del deposito'}
                                    value={`$ ${formData.amount}`}
                                    placeholder={''}
                                    disabled={true}
                                />
                            </div>
                            <h3 className="text-md text-center font-semibold text-gray-900 my-8">Seleciona la fecha vencimiento</h3>
                            <div className='w-full flex justify-center items-center'>
                                <InputDate
                                    id={'dateExpired'}
                                    selected={selectExpireDate}
                                    onChange={setSelectExpireDate}
                                    beforeToDay={true}
                                />
                            </div>
                            <div className='w-full flex justify-around mt-8'>
                                <button
                                    type='button'
                                    disabled={loading}
                                    className='disabled:cursor-not-allowed rounded-lg transition py-2.5 px-4  font-semibold text-md text-white text-center bg-red-600 hover:bg-red-800'
                                    onClick={() => handleCancelValidationPayment()}
                                >
                                    Rechazar pago
                                </button>
                                <button
                                    type='button'
                                    disabled={loading}
                                    className='disabled:cursor-not-allowed rounded-lg transition py-2.5 px-4  font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-800'
                                    onClick={() => handleValidationPayment()}
                                >
                                    Validar pago
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </Wrapper>
            <ConfirmationValidatePaymentModal
                open={openConfirmationValidationPaymentModal}
                setOpen={setOpenConfirmationValidationPaymentModal}
                cancelButtonRef={cancelCreateCourseRef}
                confirmAction={comfirmationValidationPayment}
                title={'Validar pago de suscripcion'}
                message={'Estas seguro de validar el pago de suscripcion, una vez aceptado no podras revertir esta accion.'}
                labelButonConfirm={'Validar pago'}
                loading={loading}
            />
            <ModalDeclinePayment
                open={openDeclinePaymentModal}
                setOpen={setOpenDeclinePaymentModal}
                paymentId={id}
            />
        </ContainerFull>
    )
}
