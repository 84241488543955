import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { useParams } from "react-router-dom";

import { updatedPassword } from '../../redux/actions/user';
import { jwtDecode } from 'jwt-decode';

import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { InputText } from '../../components/inputs/InputText';

import logo from '../../static/image/logo.png';
import image from '../../static/image/1.png';

export const FormForgetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    const { email } = jwtDecode(token);

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    const [loading, setLoading] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const matchPassword = () => {
        if (formData.password.length === 0 || formData.confirmPassword.length === 0) {
            setLoading(false);
            toast.error('Ingresa tu nueva contraseña, para poder continuar');
            return false;
        }
        if (formData.password !== formData.confirmPassword) {
            setLoading(false);
            toast.error('Las contraseñas no son las mismas, revisa tus contraseñas');
            return false;
        }
        if (formData.password === formData.confirmPassword) {
            return true;
        }
    }

    const handleUpdatePassword = (e) => {
        e.preventDefault();
        setLoading(true);
        const match = matchPassword();
        if (match) {
            const password = formData.password;
            dispatch(updatedPassword({ email, password }))
                .then((result) => {
                    if (result.status === 200) {
                        toast.success(result.message);
                        navigate('/');
                    }
                    setLoading(false);
                });
        }
    }


    if (!email)
        return (
            <div>
                <h1>Tu enlace esta vencido genera uno nuevo.</h1>
            </div>
        );

    const renderStep = () => (
        <div
            className='w-full h-screen bg-white flex flex-col justify-between'
            style={{ height: "100dvh" }}
        >
            {/* Header form */}
            <div className='mt-[100px]'>
                <div className='flex justify-center items-center'>
                    <div className='w-[45%] md:w-[40%]'>
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div className='flex justify-center items-center mt-4'>
                    <div className='w-[85%] md:w-2/3'>
                        <h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
                            Ingresa tu nueva contraseña, para que puedas acceder a tu cuenta
                        </h2>
                    </div>
                </div>
            </div>


            <div className='flex justify-center items-center mt-8'>
                <form
                    className='w-[85%] md:w-2/3 flex flex-col juctify-center items-center gap-8'
                    onSubmit={(e) => handleUpdatePassword(e)}
                >
                    <div className='w-full'>
                        <InputText
                            id={'password'}
                            name={'password'}
                            type={'password'}
                            label={'Nueva contraseña'}
                            onChange={(e) => onChange(e)}
                            value={formData.password}
                            placeholder={'Nueva contraseña'}
                            disabled={false}
                        />
                    </div>
                    <div className='w-full'>
                        <InputText
                            id={'confirmPassword'}
                            name={'confirmPassword'}
                            type={'password'}
                            label={'Confirma tu nueva contraseña'}
                            onChange={(e) => onChange(e)}
                            value={formData.confirmPassword}
                            placeholder={'Confirma tu nueva contraseña'}
                            disabled={false}
                        />
                    </div>

                    <div className='w-full'>
                        <button
                            type='submit'
                            disabled={loading}
                            className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white bg-indigo-600 w-full'
                        >
                            {loading
                                ? <ButtonLoader />
                                : 'Confirmar cambio de contraseña'
                            }
                        </button>
                    </div>
                    <div className='flex justify-center mt-8 mb-4'>
                        <Link to={'/'} className='font-semibold text-indigo-600 text-sm'><span className='text-gray-700'>Ya tienes una cuenta?  </span>Iniciar sesion</Link>
                    </div>
                </form>
            </div>
            {/* Footer */}
            <div className='w-full flex mb-[60px]'>
                <div className='w-full flex justify-center text-xs text-gray-400 mt-4'>
                    <p className='text-center'>Derechos Reservados © Centro de Formación Académica 2024</p>
                </div>
            </div>

        </div>
    );

    return (
        <div className='w-full flex'>
            <div className='w-full h-screen md:w-[40%]'>
                {renderStep()}
            </div>
            <div
                className='w-0 md:w-[60%] h-screen bg-cover'
                style={{ backgroundImage: `url(${image})` }}
            ></div>
        </div>
    );
};

