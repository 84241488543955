import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

export const DrawerCustom = ({
    isOpen,
    toggleDrawer,
    children,
    direction = 'right',
    sizeSM = '100%',
    sizeMD = '50%',
    sizeLG = '35%',
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction={direction}
            size={windowWidth < 768 ? sizeSM : windowWidth <= 1024 ? sizeMD : sizeLG}
        >
            <div
                className='flex flex-col overflow-auto w-full'
                style={{ height: "100vh", height: "100dvh" }}
            >
                {children}
            </div>
        </Drawer>
    )
}
