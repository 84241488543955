import {
    cleanUserNote,
    createNoteUserFailure,
    createNoteUserStart,
    createNoteUserSuccess,
    deleteNoteUserFailure,
    deleteNoteUserStart,
    deleteNoteUserSuccess,
    getAllNotesByUserIdFailure,
    getAllNotesByUserIdStart,
    getAllNotesByUserIdSuccess
} from "../reducers/noteUser";

import axios from '../../api/axios';


export const createNoteUser = ({ userId, title, content, category, createdBy }) => async (dispatch) => {
    dispatch(createNoteUserStart());
    try {
        const response = await axios.post('/noteUser/createNoteUser', {
            userId,
            title,
            content,
            category,
            createdBy
        });
        if (response.status === 201) {
            dispatch(createNoteUserSuccess());
            return {
                status: response.status,
                message: response.data.message
            };
        }
    } catch (error) {
        dispatch(createNoteUserFailure());
    }
};

export const getAllNotesByUserId = (userId) => async (dispatch) => {
    dispatch(getAllNotesByUserIdStart());
    try {
        const response = await axios.get(`/noteUser/getAllNotesUserByUserId/${userId}`);
        if (response.status === 200) {
            dispatch(getAllNotesByUserIdSuccess({ notes: response.data.data }));
        }
    } catch (error) {
        dispatch(getAllNotesByUserIdFailure());
    }
};

export const deleteNoteUser = (noteId) => async (dispatch) => {
    dispatch(deleteNoteUserStart());
    try {
        const response = await axios.delete(`/noteUser/deleteNoteUser/${noteId}`);
        if (response.status === 200) {
            dispatch(deleteNoteUserSuccess());
            return {
                status: response.status,
                message: response.data.message
            };
        }
    } catch (error) {
        dispatch(deleteNoteUserFailure());
    }
};

export const cleanAllUserNotes = () => async (dispatch) => {
    dispatch(cleanUserNote());
};

