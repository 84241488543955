import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../components/loading/Loading';
import { Title } from '../../components/Title';
import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { capitalizarPalabras, firstCapitalLetter } from '../../common/upperCaseWord';
import { formatDate } from '../../common/formatDateText';

import { statusStudents } from '../../static/data';
import { ComboBox } from '../../components/comboBox/ComboBox';
import { Tooltip, Zoom } from '@mui/material';
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';
import { toast } from 'react-hot-toast';
import { getUserById, updateProfileUser, updateProfileUser2 } from '../../redux/actions/users';
import { useNavigate } from 'react-router-dom';

export const DataUserProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userSelected, loading } = useSelector((state) => state.users);
    const {
        _id: userId,
        firstName, secondName, lastName,
        secondSurname, email, status,
        phone, dateBirth, location,
        education, paymentDeadlineDate, subscriptionPayment,
        matricula, typeUser, emailTax, rfc, businessName, taxRegime
    } = userSelected;

    const { user } = useSelector((state) => state.user);

    const { _id: updateUserId, typeUser: typeUserLoggin } = user;

    const [editStatusStudent, setEditStatusStudent] = useState(false);
    const [editDeadLinePayment, setEditDeadLinePayment] = useState(false);
    const [findStatusStudent, setFindStatusStudent] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [formData, setFormData] = useState({
        statusStudent: status ? statusStudents.find(item => item.value === status) : '',
        paymentDeadlineDate: selectedDate ? selectedDate : ''
    });

    useEffect(() => {
        setFormData({
            ...formData,
            paymentDeadlineDate: selectedDate
        });
    }, [selectedDate]);

    useEffect(() => {
        if (userSelected) {
            setFormData({
                ...formData,
                paymentDeadlineDate: paymentDeadlineDate ? new Date(paymentDeadlineDate) : '',
                statusStudent: status ? statusStudents.find(item => item.value === status) : ''
            });
            setSelectedDate(paymentDeadlineDate ? new Date(paymentDeadlineDate) : '');
        }
    }, [userSelected]);

    const cancelEditStatusStudent = () => {
        setEditStatusStudent(false);
        setFormData({
            ...formData,
            statusStudent: status ? statusStudents.find(item => item.value === status) : ''
        });
    }

    const cancelEditDeadLinePayment = () => {
        setEditDeadLinePayment(false);
        setSelectedDate(paymentDeadlineDate ? new Date(paymentDeadlineDate) : '');
        setFormData({
            ...formData,
            paymentDeadlineDate: paymentDeadlineDate ? new Date(paymentDeadlineDate) : ''
        });
    }

    const filteredstatusStudents = findStatusStudent === ''
        ? statusStudents
        : statusStudents.filter((statusStudent) =>
            statusStudent.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findStatusStudent.toLowerCase().replace(/\s+/g, ''))
        );


    const preparePayload = () => {
        let updateState = {};
        if (formData.statusStudent !== status) {
            updateState.status = formData.statusStudent.value;
        }
        if (formData.paymentDeadlineDate !== paymentDeadlineDate) {
            updateState.paymentDeadlineDate = formData.paymentDeadlineDate;
        }
        updateState.updatedBy = updateUserId;
        return updateState;
    }


    const handleUpdateProfile = () => {
        const payload = preparePayload();
        setLoadingUpdate(true);
        dispatch(updateProfileUser2(payload, userId))
            .then((response) => {
                if (response.status === 201) {
                    toast.success(response.message);
                    setLoadingUpdate(false);
                    // navigate(`/perfil/${userId}`);
                    dispatch(getUserById(userId))
                    setEditStatusStudent(false);
                    setEditDeadLinePayment(false);
                    // dispatch(getUserById(userId));
                } else {
                    setLoadingUpdate(false);
                    toast.error(response.message);
                }
            });
    }

    return (
        <>
            {
                loading ? (
                    <Loading />
                ) : (
                    <>
                        {
                            userSelected ? (
                                <>
                                    <Title title={'Datos personales'} />
                                    <PropertyListItem>
                                        <PropertyItem
                                            title={`Matricula`}
                                            description={matricula}
                                        />
                                        <PropertyItem
                                            title={`Nombre completo`}
                                            description={`${firstCapitalLetter(firstName)} ${secondName ? firstCapitalLetter(secondName) : ''} ${firstCapitalLetter(lastName)} ${secondName ? firstCapitalLetter(secondSurname) : ''}`}
                                        />
                                        <PropertyItem
                                            title={`Correo electrónico`}
                                            description={email ? email : ''}
                                        />
                                        <PropertyItem
                                            title={`Telefono`}
                                            description={phone ? phone : ''}
                                        />
                                        <PropertyItem
                                            title={`Fecha de nacieminto`}
                                            description={capitalizarPalabras(formatDate(dateBirth))}
                                        />
                                        {
                                            typeUser !== 'estudiante' && (
                                                <PropertyItem
                                                    title={`Tipo de usuario`}
                                                    description={firstCapitalLetter(typeUser)}
                                                />
                                            )
                                        }
                                        <PropertyItem
                                            title={`Nivel de educacion`}
                                            description={education ? capitalizarPalabras(education) : ''}
                                        />
                                        {
                                            typeUser === 'estudiante' && (
                                                <PropertyItem
                                                    title={`Monto de la suscripcion`}
                                                    description={subscriptionPayment ? `$ ${subscriptionPayment}` : ''}
                                                />
                                            )
                                        }
                                        <PropertyItem
                                            title={`Lugar de residencia`}
                                            description={capitalizarPalabras(location)}
                                        />
                                        {
                                            typeUserLoggin === 'estudiante' && (
                                                <PropertyItem
                                                    title={`Estatus del estudiante`}
                                                    description={capitalizarPalabras(status)}
                                                />

                                            )
                                        }
                                        {
                                            typeUserLoggin !== 'estudiante' && typeUserLoggin !== 'profesor' && (
                                                <>
                                                    {
                                                        editStatusStudent ? (
                                                            <div className='w-full flex justify-center p-4'>
                                                                <div className='w-[80%] flex flex-col gap-4'>
                                                                    <h3 className="text-sm mb-4 font-semibold text-gray-900">Estas seguro de modificar el estado del estudiante?</h3>
                                                                    <div className='w-full'>
                                                                        <ComboBox
                                                                            filterData={filteredstatusStudents}
                                                                            query={findStatusStudent}
                                                                            setQuery={setFindStatusStudent}
                                                                            selected={formData}
                                                                            setSelected={setFormData}
                                                                            placeholder='Estado del alumno'
                                                                            property='statusStudent'
                                                                        />
                                                                    </div>
                                                                    <div className='w-full flex justify-end gap-4 m-4'>
                                                                        <Tooltip title="Cancelar accion" TransitionComponent={Zoom} placement='top'>
                                                                            <button
                                                                                type='button'
                                                                                onClick={() => cancelEditStatusStudent()}
                                                                                className='rounded-md p-2 font-semibold text-sm text-white bg-red-600 shadow-sm shadow-red-600 hover:bg-red-800'
                                                                            >Cancelar</button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Actualizar estatus estudiante" TransitionComponent={Zoom} placement='top'>
                                                                            <button
                                                                                type='button'
                                                                                onClick={() => handleUpdateProfile()}
                                                                                className='rounded-md p-2 font-semibold text-sm text-white bg-green-600 shadow-sm shadow-green-600 hover:bg-green-800'
                                                                            >Confirmar</button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            < PropertyItem
                                                                title={`Estatus del estudiante`}
                                                                description={capitalizarPalabras(status)}
                                                                editProperty={true}
                                                                setEditProperty={setEditStatusStudent}
                                                            />

                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        {
                                            typeUserLoggin === 'estudiante' && (
                                                <PropertyItem
                                                    title={`Proxima fecha de pago`}
                                                    description={capitalizarPalabras(formatDate(paymentDeadlineDate))}
                                                />
                                            )
                                        }
                                        {
                                            typeUserLoggin !== 'estudiante' && typeUserLoggin !== 'profesor' && (
                                                <>
                                                    {
                                                        editDeadLinePayment ? (
                                                            <div className='w-full flex justify-center p-4'>
                                                                <div className='w-[80%] flex flex-col gap-4'>
                                                                    <h3 className="text-sm mb-4 font-semibold text-gray-900">Estas seguro de modificar la fecha de limite pago del estudiante?</h3>
                                                                    <div className='w-full'>
                                                                        <InputDateWithIcon
                                                                            id={'dateBirth'}
                                                                            label={''}
                                                                            onChange={setSelectedDate}
                                                                            value={selectedDate}
                                                                            placeholder={'Fecha limite de pago'}
                                                                            beforeToDay={true}
                                                                        />
                                                                    </div>
                                                                    <div className='w-full flex justify-end gap-4 m-4'>
                                                                        <Tooltip title="Cancelar accion" TransitionComponent={Zoom} placement='top'>
                                                                            <button
                                                                                type='button'
                                                                                onClick={() => cancelEditDeadLinePayment()}
                                                                                className='rounded-md p-2 font-semibold text-sm text-white bg-red-600 shadow-sm shadow-red-600 hover:bg-red-800'
                                                                            >Cancelar</button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Actualizar la fecha limite de pago" TransitionComponent={Zoom} placement='top'>
                                                                            <button
                                                                                type='button'
                                                                                onClick={() => handleUpdateProfile()}
                                                                                className='rounded-md p-2 font-semibold text-sm text-white bg-green-600 shadow-sm shadow-green-600 hover:bg-green-800'
                                                                            >Confirmar</button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            < PropertyItem
                                                                title={`Fecha limite de pago`}
                                                                description={capitalizarPalabras(formatDate(paymentDeadlineDate))}
                                                                editProperty={true}
                                                                setEditProperty={setEditDeadLinePayment}
                                                            />

                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </PropertyListItem>

                                    <div className="mt-10">
                                        <Title title={'Datos fiscales'} />
                                        <PropertyListItem>
                                            <PropertyItem
                                                title={`Correo electrónico fiscal`}
                                                description={emailTax}
                                            />
                                            <PropertyItem
                                                title={`RFC`}
                                                description={rfc ? rfc.toUpperCase() : ''}
                                            />
                                            <PropertyItem
                                                title={`Razon social`}
                                                description={businessName ? businessName.toUpperCase() : ''}
                                            />
                                            <PropertyItem
                                                title={`Regimen fiscal`}
                                                description={taxRegime ? taxRegime.toUpperCase() : ''}
                                            />
                                        </PropertyListItem>
                                    </div>
                                </>

                            ) : (
                                <>Cargando datos del usuario</>
                            )
                        }
                    </>

                )
            }
        </>
    )
}
