import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { cleanActionSelectedPreRegister, getAllPreRegister } from '../../redux/actions/preRegistration';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';

import { capitalizarPalabras } from '../../common/upperCaseWord';
import { formatDate } from '../../common/formatDateText';
import { exportToExcel } from '../../common/importDataToExcel';
import { verifyStateApp } from '../../redux/actions/stateApp';
import { Loading } from '../../components/loading/Loading';
import { DrawerFilterCandidates } from './DrawerFilterCandidates';
import { AdjustmentsHorizontalIcon, DocumentArrowDownIcon, EyeIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip, Zoom } from '@mui/material';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export const CandidatesPage = () => {
	const dispatch = useDispatch();
	const [isOpenDrawerFilters, setIsOpenDrawerFilters] = useState(false);

	const {
		allPreRegisters,
		loading,
	} = useSelector((state) => state.preRegistration);

	const { user } = useSelector((state) => state.user);

	const { _id: id, roles, typeUser } = user;

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const typeUserCanValidate = ['coordinador', 'director', 'ventas'];
	const typeUserCanView = ['cobranza', 'subdirector', 'control escolar'];
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		dispatch(cleanActionSelectedPreRegister())
	}, [dispatch]);


	useEffect(() => {
		dispatch(getAllPreRegister({
			id: id,
			roles: roles,
			startDate: new Date('1/1/2024'),
			endDate: new Date()
		}));
	}, [id, roles, dispatch]);

	const toggleDrawerFilters = () => {
		setIsOpenDrawerFilters((prevState) => !prevState);
	}

	const rows = allPreRegisters;

	const columns = [
		{
			field: 'firstName',
			headerName: 'Nombre',
			flex: 1,
			renderCell: (params) => (
				<p>{capitalizarPalabras(params.value)}</p>
			),
		},
		{
			field: 'lastName',
			headerName: 'Apellido',
			flex: 1,
			renderCell: (params) => (
				<p>{capitalizarPalabras(params.value)}</p>
			),
		},
		{ field: 'email', headerName: 'Correo Electronico', flex: 1 },
		{ field: 'location', headerName: 'Locacion', flex: 1 },
		{ field: 'education', headerName: 'Nivel educativo', flex: 1 },
		{
			field: 'language',
			headerName: 'Idioma',
			flex: 1,
			renderCell: (params) => (
				<p>{capitalizarPalabras(params.value)}</p>
			),
		},
		{
			field: 'coordinador',
			headerName: 'Coordinador',
			flex: 1,
			renderCell: (params) => (
				<p>{capitalizarPalabras(params.value)}</p>
			),
		},
		{
			field: 'createdAt',
			headerName: 'Fecha de creacion',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{formatDate(params.value)}</p>
			),
		},
		{
			field: 'status',
			headerName: 'Estatus',
			flex: 1,
			zIndex: 10000,
			renderCell: (params) => (
				<div className='w-full flex justify-center items-center'>
					<div className={classNames(
						params.value === 'completado'
							? 'text-lime-600 ring-lime-600 shadow-lime-600 bg-lime-600'
							: params.value === 'prospecto' ? 'text-orange-500 ring-orange-500 shadow-orange-500 bg-orange-500'
								: 'text-red-500 ring-red-500 shadow-red-500 bg-red-500',
						'text-white flex justify-center border-none w-[120px] px-1 py-1 ring-2 shadow-md rounded-xl uppercase'
					)}>
						<span className="text-[.8rem]">{params.value}</span>
					</div>
				</div>
			),
		},
		{
			field: 'action',
			headerName: '',
			flex: 1,
			sortable: false,
			disableColumnMenu: true,
			renderCell: (params) =>
			(
				<div className='w-full flex justify-center gap-4'>
					{
						params.row.status === 'validando' && typeUserCanValidate.includes(typeUser)
							?
							(
								<Tooltip title="Validar pago del candidato" TransitionComponent={Zoom} placement='left'>
									<Link
										to={`/candidatos/${params.id}`}
										className="flex justify-center shadow-sm shadow-indigo-600 bg-indigo-600 uppercase px-4 py-2 text-slate-50 rounded hover:bg-indigo-800"
									>
										<CheckCircleIcon className="block h-5 w-5" aria-hidden="true" />
									</Link>
								</Tooltip>
							)
							: params.row.status === 'completado' || params.row.status === 'prospecto'
								?
								(
									<Tooltip title="Ver detalle del proceso" TransitionComponent={Zoom} placement='left'>
										<Link
											to={`/mostrar-candidato/${params.id}`}
											className="flex justify-center shadow-sm shadow-sky-600 bg-sky-600 px-4 py-2 uppercase text-slate-50 rounded hover:bg-sky-800"
										>
											<EyeIcon className="block h-5 w-5" aria-hidden="true" />
										</Link>
									</Tooltip>
								)
								: typeUserCanView.includes(typeUser)
									? (
										<Tooltip title="Ver detalle del proceso" TransitionComponent={Zoom} placement='left'>

											<Link
												to={`/mostrar-candidato/${params.id}`}
												className="flex justify-center shadow-sm shadow-sky-600 bg-sky-600 px-4 py-2 uppercase text-slate-50 rounded hover:bg-sky-800"
											>
												<EyeIcon className="block h-5 w-5" aria-hidden="true" />
											</Link>
										</Tooltip>
									)
									: null
					}

				</div>
			),
		},
	];

	const handleSearchChange = (event) => {
		const searchText = event.target.value;
		setSearchText(searchText);
	};

	const filteredRows = rows.filter(
		(row) =>
			row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.education?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.language?.toLowerCase().includes(searchText.toLowerCase()) ||
			formatDate(row.createdAt)?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.coordinador?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.status?.toLowerCase().includes(searchText.toLowerCase())
	);

	const formatDataExcel = async (data) => {
		const formattedData = data.map(item => ({
			nombre: item.firstName,
			primer_apellido: item.lastName,
			correo_electronico: item.email,
			telefono: item.phone,
			fecha_nacimiento: formatDate(item.dateBirth),
			localizacion: item.location,
			nivel_educativo: item.education,
			lenguajes: item.language,
			status: item.status,
			fecha_registro: formatDate(item.createdAt),
			cordinador: item.coordinador,
		}));
		return formattedData;
	}

	const handleImportData = async (data, name) => {
		dispatch(verifyStateApp())
			.then((response) => {
				if (response.data === false) {
					toast.error(response.message)

				} else {
					confirDownloadFile(data, name);
				}
			});
	}

	const confirDownloadFile = async (data, name) => {
		const dataReady = await formatDataExcel(data);
		const response = exportToExcel(dataReady, name);
		response.data ? toast.error(response.message) : toast.success(response.message);
	}

	const handleApllyFilter = () => {
		if (!startDate || !endDate)
			return toast.error('No hay filtros por aplicar.')
		if (startDate.getTime() > endDate.getTime())
			return toast.error('La fecha de inicio debe ser menor a la fecha final.')
		if (startDate.getTime() === endDate.getTime())
			return toast.error('Las fechas no pueden ser iguales.')
		if (!startDate && endDate)
			return toast.error('Debes seleccionar una fecha de inicio.')
		if (startDate && !endDate)
			return toast.error('Debes seleccionar una fecha final.')
		dispatch(getAllPreRegister({ id, roles: roles, startDate: startDate, endDate: endDate }));
		toggleDrawerFilters();
	}

	const handleRemoveFilter = () => {
		setStartDate(null);
		setEndDate(null);
		dispatch(getAllPreRegister({ id, roles: roles, startDate: new Date('1/1/2024'), endDate: new Date() }));
		toggleDrawerFilters();
	}

	return (
		<ContainerFull>
			<Heading
				title={`Lista de candidatos`}
				subtitle={`Muestra la lista candidatos con su estatus de proceso`}
				center={false}
			/>
			<div className='w-full flex justify-end mt-4 gap-4'>
				<Tooltip title="Panel de filtros" TransitionComponent={Zoom} placement='top'>
					<button
						type='button'
						onClick={() => toggleDrawerFilters()}
						className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
					><AdjustmentsHorizontalIcon className="block h-5 w-5" aria-hidden="true" /></button>
				</Tooltip>
				<Tooltip title="Descargar archivo de Excel" TransitionComponent={Zoom} placement='top'>
					<button
						type='button'
						onClick={() => handleImportData(allPreRegisters, 'Lista_de_candidatos.xlsx')}
						className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
					><DocumentArrowDownIcon className="block h-5 w-5" aria-hidden="true" /></button>
				</Tooltip>
			</div>

			{allPreRegisters && (
				<>
					<Wrapper>
						<input
							type="text"
							placeholder="Buscar un candidato"
							value={searchText}
							onChange={handleSearchChange}
							className='block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
					</Wrapper>

					<Wrapper>
						{
							loading
								? (
									<Loading />
								) : (
									<>
										{
											rows.length > 0
												? (
													<div className='w-full overflow-hidden'>
														<DataGrid
															rows={filteredRows}
															columns={columns}
															// onRowClick={handleRowClick}
															localeText={esES.components.MuiDataGrid.defaultProps.localeText}
															initialState={{
																pagination: {
																	paginationModel: { pageSize: 15, page: 0, },
																},
															}}
															pageSizeOptions={[10, 15, 25]}
														/>
													</div>
												) : (
													<p className='text-gray-400'>No hay registro de candidatos</p>
												)
										}
									</>
								)
						}

					</Wrapper>
				</>
			)}
			<DrawerFilterCandidates
				isOpenDrawerFilters={isOpenDrawerFilters}
				toggleDrawerFilters={toggleDrawerFilters}
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				handleRemoveFilter={handleRemoveFilter}
				handleApllyFilter={handleApllyFilter}
			/>
		</ContainerFull>
	);
}
