import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { getSelectedPreRegister, requestChangeCoordinador, validateCandidate } from '../../redux/actions/preRegistration';
import { optionsCoordinadors, optionsAllAccountsBank, optionsAllCourseList } from '../../redux/actions/options';

import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Title } from '../../components/Title';
import { Wrapper } from '../../components/Wrapper';
import { formatDate, isDateGreaterThanToday } from '../../common/formatDateText';
import { capitalizarPalabras, } from '../../common/upperCaseWord';
import { ConformationValidationModal } from './ConformationValidationModal';
import { InputDate } from '../../components/inputDate/InputDate';
import { InputText } from '../../components/inputs/InputText';
import { ImageZoom } from '../../components/imageZoom/ImageZoom';
import { ModalCancelPreRegister } from './ModalCancelPreRegister';
import { InputCourseMulti } from '../../components/inputCourseMulti/InputCourseMulti';
import { ModalChangeCoordinator } from './ModalChangeCoordinator';

export const ValidateCandidate = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.user);
    const { preRegisterSelected } = useSelector((state) => state.preRegistration);
    const { accountsBank, coursesList } = useSelector((state) => state.options);
    const { typeUser } = user;
    const [selectExpireDate, setSelectExpireDate] = useState(null);
    const cancelCreateCourseRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [openConfirmationValidationModal, setOpenConfirmationValidationModal] = useState(false);
    const [openCancelPreRegisterModal, setOpenCancelPreRegisterModal] = useState(false);
    const [openChangeCoordinatorModal, setOpenChangeCoordinatorModal] = useState(false);
    const [coursesSelected, setCoursesSelected] = useState([])
    const [formData, setFormData] = useState({
        idPreregister: id,
        coordinador: '',
        coordinadorName: '',
        createdBy: '',
        account: '',
        accountNumber: '',
        paymentDeadlineDate: null,
        idCourses: '',
        message: '',
        amount: '0',
        subscriptionPayment: '',
    });

    useEffect(() => {
        dispatch(getSelectedPreRegister(id))
    }, [])

    useEffect(() => {
        dispatch(optionsCoordinadors());
        dispatch(optionsAllAccountsBank());
        dispatch(optionsAllCourseList());
    }, []);

    useEffect(() => {
        if (preRegisterSelected?.status === 'completado') {
            navigate('/candidatos');
        }
    }, [preRegisterSelected])

    useEffect(() => {
        if (isDateGreaterThanToday(selectExpireDate)) {

            setFormData((prevData) => ({
                ...prevData,
                paymentDeadlineDate: selectExpireDate,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                paymentDeadlineDate: '',
            }));
            if (selectExpireDate !== null) {
                toast.error('La fecha de vencimiento debe ser mayor a la fecha actual');
            }
        }
    }, [selectExpireDate])

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
        }));
    }, [accountsBank])

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
            amount: preRegisterSelected?.amount || '0',
            coordinadorName: coordinador ? `${coordinador?.firstName} ${coordinador?.lastName}` : '',
            coordinador: coordinador?._id || '',
            createdBy: coordinador?._id || '',
        }));
    }, [preRegisterSelected]);


    const {
        firstName, secondName, lastName, secondSurname, email,
        phone, dateBirth, location,
        education, language, status,
        createdAt, account, coordinador,
        urlName, rfc, usageRights
    } = preRegisterSelected;

    const getIdAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.value;
    }

    const getNumberAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.description;
    }

    const validationData = () => {
        if (coursesSelected.length !== language.split(' ').length) {
            toast.error(`Debes asignar un curso por cada idioma seleccionado por el candidato`);
            return false;
        }
        if (formData.coordinador.trim() === '') {
            toast.error('Selecciona un coordinador para el candidato');
            return false;
        }
        if (formData.account.trim() === '') {
            toast.error('Selecciona la cuenta de deposito para el candidato');
            return false;
        }
        if (formData.paymentDeadlineDate === null || formData.paymentDeadlineDate === '') {
            toast.error('Selecciona la fecha de vencimiento de pago');
            return false;
        }
		if (formData.subscriptionPayment === '') {
			toast.error('El ingresa el monto a depositar por el estudiante');
			return false;
		}
		if (isNaN(formData.subscriptionPayment)) {
			setFormData((prevData) => ({
				...prevData,
				'subscriptionPayment': '',
			}));
			toast.error('El monto ingresado no es valido');
			return false;
		}
        return true;
    }

    const handleValidationCadidate = () => {
        const validation = validationData();
        setFormData((prevData) => ({
            ...prevData,
            message: createMessageListCourses(),
            idCourses: coursesSelected

        }));
        if (validation) {
            setOpenConfirmationValidationModal(true);
        }
    }

    const comfirmationValidation = () => {
        setLoading(true);
        dispatch(validateCandidate(formData))
            .then((result) => {
                if (result.status === 201) {
                    toast.success(result.message);
                    navigate('/candidatos');
                } else {
                    toast.error(result.message);
                }
                setOpenConfirmationValidationModal(false);
                setLoading(false);
            });
    }

    const getDaysOfCourse = (days) => {
        if (!Array.isArray(days) || days.length === 0) {
            return 'No hay días seleccionados';
        }
        const formattedDays = days.map(dayObj => dayObj.day.charAt(0).toUpperCase() + dayObj.day.slice(1)).join(', ');
        return formattedDays;
    }

    const createMessageListCourses = () => {
        let allMessages = '';
        coursesSelected.forEach((courseId) => {
            const course = coursesList.find((courseList) => courseList._id === courseId);

            if (course) {
                allMessages += `
                    <p style="text-align: center; margin-top: 10px; font-family: Verdana, Geneva, Tahoma, sans-serif;">
                        ESTÁS INSCRITO EN EL CURSO DE
                        <p style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif; font-weight: bold;">${capitalizarPalabras(course.language)} - ${capitalizarPalabras(course.level)}</p>
                    </p>
                    <p style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">
                        EL INICIO DE CURSO ES
                        <p style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif; font-weight: bold;">${capitalizarPalabras(formatDate(course.fromDate))}</p>
                    </p>
                    <p style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">
                        EL HORARIO DE CLASES ES
                        <p style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif; font-weight: bold;">Son los ${getDaysOfCourse(course.days)}</p>
                        <p style="text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif; font-weight: bold;">
                            ${course.hours[0].time} - ${course.hours[course?.hours.length - 1].time}
                        </p>
                    </p>
                `;
            }
        });
        return allMessages;
    };

    const handleRequestChangeCoordinador = () => {
        setLoading(true);
        dispatch(requestChangeCoordinador(id))
            .then((result) => {
                if (result.status === 200) {
                    toast.success(result.message);
                    navigate('/candidatos');
                } else {
                    toast.error(result.message);
                }
                setLoading(false);
            });
    }
    
    return (
        <ContainerFull>
            <Heading
                title={`Perfil de usuario de pre registro`}
                subtitle={`Examina y verifica la información proporcionada por el candidato. Si la información es correcta, procede a la validación.`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Informacion del cadidato' />
                        <PropertyListItem>
                            <PropertyItem
                                title={`Nombre completo`}
                                description={`${capitalizarPalabras(firstName)} ${capitalizarPalabras(secondName)} ${capitalizarPalabras(lastName)} ${capitalizarPalabras(secondSurname)}`}
                            />
                            <PropertyItem
                                title={`Email`}
                                description={email}
                            />
                            <PropertyItem
                                title={`Telefono`}
                                description={phone}
                            />
                            <PropertyItem
                                title={`Fecha de nacimiento`}
                                description={capitalizarPalabras(formatDate(dateBirth))}
                            />
                            <PropertyItem
                                title={`Ubicacion`}
                                description={capitalizarPalabras(location)}
                            />
                            <PropertyItem
                                title={`Nivel educativo`}
                                description={capitalizarPalabras(education)}
                            />
                            <PropertyItem
                                title={`Estatus del preregistro`}
                                description={capitalizarPalabras(status)}
                            />
                            <PropertyItem
                                title={`Idioma seleccionado por el usuario`}
                                description={capitalizarPalabras(language)}
                            />
                            <PropertyItem
                                title={`Fecha de registro`}
                                description={capitalizarPalabras(formatDate(createdAt))}
                            />
                            <PropertyItem
                                title={`Termimos y condiciones`}
                                description={usageRights && usageRights ? 'Aceptado' : 'No aceptado'}
                            />
                            <PropertyItem
                                title={`RFC`}
                                description={rfc ? rfc.toUpperCase() : 'No proporcionado'}
                            />
                        </PropertyListItem>
                    </div>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Comprobante de pago' center={true} />
                        <div className='w-full flex flex-col justify-center items-center'>
                            <ImageZoom imageUrl={urlName} />
                        </div>
                        <div className='w-[70%] mx-auto flex flex-col mt-8'>
                            <div className='mb-4'>
                                <InputText
                                    id={'account'}
                                    name={'account'}
                                    type={'text'}
                                    label={'Cuenta de deposito'}
                                    value={formData.accountNumber}
                                    placeholder={''}
                                    disabled={true}
                                />
                            </div>
                            <div className='mb-4'>
                                <InputText
                                    id={'account'}
                                    name={'account'}
                                    type={'text'}
                                    label={'Coordinador asignado'}
                                    value={capitalizarPalabras(formData.coordinadorName)}
                                    placeholder={''}
                                    disabled={true}
                                />
                            </div>
                            <div className='mb-4'>
                                <InputText
                                    id={'amount'}
                                    name={'amount'}
                                    type={'text'}
                                    label={'Cantidad del deposito'}
                                    value={`$ ${formData.amount}`}
                                    placeholder={''}
                                    disabled={true}
                                />
                            </div>
                            <div className='mb-4'>
                                <InputText
                                    id={'amountPay'}
                                    name={'amountPay'}
                                    type={'text'}
                                    label={'Ingresa la cantidad que el usuario va estar depositando'}
                                    value={formData.subscriptionPayment}
                                    placeholder={'Ingresa la cantidad a depositar'}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, subscriptionPayment: e.target.value }))}
                                    disabled={false}
                                />
                            </div>
                        </div>
                        <div className='w-full flex flex-col gap-4 mt-2 md:mt-12'>
                            <div className='w-full flex justify-center'>
                                <button
                                    type='button'
                                    disabled={loading}
                                    className='w-full md:w-[60%] disabled:cursor-not-allowed rounded-lg transition py-2.5 px-4  font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-800'
                                    onClick={() => handleRequestChangeCoordinador(true)}
                                >
                                    Solicitar cambio de coordinador
                                </button>
                            </div>
                            {
                                typeUser !== 'coordinador' && (
                                    <div className='w-full flex justify-center'>
                                        <button
                                            type='button'
                                            disabled={loading}
                                            className='w-full md:w-[60%] disabled:cursor-not-allowed rounded-lg transition py-2.5 px-4  font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-800'
                                            onClick={() => setOpenChangeCoordinatorModal(true)}
                                        >
                                            Modificar coordinador
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Wrapper>

            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row border-[1px] border-gray-200 rounded-lg p-4'>
                    <div className='w-full lg:w-[50%]'>
                        <Title title='Confirmar validacion' />
                        <div className='w-[95%] mx-auto mb-6'>
                            <h3 className="text-md font-semibold text-gray-900 my-8">Seleciona un curso para inscribir al candidato</h3>
                            <div className='max-h-[500px] overflow-auto bg-gray-100 py-2 md:py-4 px-2 md:px-4 shadow-sm'>
                                <InputCourseMulti
                                    coursesList={coursesList}
                                    coursesSelected={coursesSelected}
                                    setCoursesSelected={setCoursesSelected}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:w-[50%] flex flex-col justify-center items-center'>
                        <h3 className="text-md text-center font-semibold text-gray-900 my-8">Seleciona la fecha vencimiento</h3>
                        <div className='w-full flex justify-center items-center'>
                            <InputDate
                                id={'dateExpired'}
                                selected={selectExpireDate}
                                onChange={setSelectExpireDate}
                            />
                        </div>
                        <div className='w-[90%] md:w-[80%] flex flex-col md:flex-row gap-4 mt-6 md:mt-12'>
                            <div className='w-full flex justify-end'>
                                <button
                                    type='button'
                                    className='w-full md:w-[80%] disabled:cursor-not-allowed rounded-lg transition py-2.5 px-4 font-semibold text-md text-red-600 text-center ring-2 ring-inset ring-red-500 bg-white hover:bg-red-500 hover:text-white'
                                    onClick={() => setOpenCancelPreRegisterModal(true)}
                                >
                                    Rechazar candidato
                                </button>

                            </div>
                            <div className='w-full flex justify-start'>
                                <button
                                    type='button'
                                    disabled={loading}
                                    className='w-full md:w-[80%] disabled:cursor-not-allowed rounded-lg transition py-2.5 px-4  font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-800'
                                    onClick={() => handleValidationCadidate()}
                                >
                                    Validar candidato
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            <ConformationValidationModal
                open={openConfirmationValidationModal}
                setOpen={setOpenConfirmationValidationModal}
                cancelButtonRef={cancelCreateCourseRef}
                confirmAction={comfirmationValidation}
                title={'Validar candidato'}
                message={'Estas seguro de validar la inscripcion del candidato?'}
                labelButonConfirm={'Aceptar candidato'}
                loading={loading}
            />
            <ModalCancelPreRegister
                open={openCancelPreRegisterModal}
                setOpen={setOpenCancelPreRegisterModal}
                preRegisterId={id}
            />
            <ModalChangeCoordinator
                open={openChangeCoordinatorModal}
                setOpen={setOpenChangeCoordinatorModal}
                preRegisterId={id}
            />
        </ContainerFull>
    )
}
