import { createSlice } from '@reduxjs/toolkit';

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        loading: false,
        posts: [],
    },
    reducers: {
        getAllPostStart: (state) => {
            state.loading = true;
        },
        getAllPostSuccess: (state, { payload: { posts } }) => {
            state.loading = false;
            state.posts = posts;
        },
        getAllPostFailure: (state) => {
            state.loading = false;
        },
        cleanPost: (state, action) => {
            state.posts = [];
            state.loading = false;
        },
        createPostStart: (state) => {
            state.loading = true;
        },
        createPostSuccess: (state) => {
            state.loading = false;
        },
        createPostFailure: (state) => {
            state.loading = false;
        },
        deletePostStart: (state) => {
            state.loading = true;
        },
        deletePostSuccess: (state, { payload: { posts } }) => {
            state.loading = false;
            state.posts = posts;
        },
        deletePostFailure: (state) => {
            state.loading = false;
        },
    }
});

export const {
    getAllPostStart,
    getAllPostSuccess,
    getAllPostFailure,
    createPostStart,
    createPostSuccess,
    createPostFailure,
    deletePostStart,
    deletePostSuccess,
    deletePostFailure,
    cleanPost,
} = homeSlice.actions;
export default homeSlice.reducer;