import {
    createPostFailure,
    createPostStart,
    createPostSuccess,
    deletePostFailure,
    deletePostStart,
    deletePostSuccess,
    getAllPostFailure,
    getAllPostStart,
    getAllPostSuccess
} from "../reducers/home";

import axios from '../../api/axios';

export const createNewPost = (
    file,
    userId,
    message,
    link,
    title,
    groupOfUser,
    releaseDate,
    expirationDate,
) => async (dispatch) => {
    dispatch(createPostStart());
    try {
        if (!file) {
            const response = await axios.post('/post/createNewPost', {
                userId,
                message,
                link,
                title,
                groupOfUser,
                releaseDate,
                expirationDate,
            });
            if (response.status === 201) {
                dispatch(createPostSuccess());
                return {
                    status: response.status,
                    message: response.data.message
                };
            }
        } else {
            const formData = new FormData();
            formData.append('file', file);
            formData.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
            formData.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
            formData.append('upload_preset', 'fca-intranet');

            const res = await axios.post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`, formData);

            if (res.status === 200) {
                const response = await axios.post('/post/createNewPost', {
                    urlName: res.data.secure_url,
                    publicId: res.data.public_id,
                    userId,
                    message,
                    link,
                    title,
                    groupOfUser,
                    releaseDate,
                    expirationDate,
                });
                if (response.status === 201) {
                    dispatch(createPostFailure());
                    return {
                        status: response.status,
                        message: response.data.message
                    };
                }
            }
        }
    } catch (error) {
        dispatch(createPostFailure());
    }
};

export const getUserPosts = (userId) => async (dispatch) => {
    dispatch(getAllPostStart());
    try {
        const response = await axios.get(`/post/getUserPosts/${userId}`);
        if (response.status === 200) {
            dispatch(getAllPostSuccess(response.data));
            
        }
    } catch (error) {
        dispatch(getAllPostFailure());
    }
}

export const deletePost = (postId, userId) => async (dispatch) => {
    dispatch(deletePostStart());
    try {
        const response = await axios.delete(`/post/deletePost/${postId}/${userId}`);
        if (response.status === 200) {
            dispatch(deletePostSuccess(response.data));
            return {
                status: response?.status || null,
                message: response?.data.message
            };
        }
    } catch (error) {
        dispatch(deletePostFailure());
    }
}