import { FormPreRegistration } from './FormPreRegistration';
import image from '../../static/image/4.png';

export const PreRegistrationPage = () => {
    return (
        <div className='w-full flex'>
            <div className='w-full h-screen md:w-1/3'>
                <FormPreRegistration />
            </div>
            <div
                className='w-0 md:w-2/3 h-screen bg-cover'
                style={{ backgroundImage: `url(${image})` }}
            ></div>
        </div>
    );
}