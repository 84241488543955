import { Tooltip, Zoom } from "@mui/material";
import { PencilSquareIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const PropertyItem = ({
    title,
    description,
    positionTile = "md:text-right",
    editProperty = false,
    setEditProperty
}) => {
    return (
        <div className={`
            sm:px-0
            px-4 
            py-2
            md:py-4
            sm:grid 
            sm:grid-cols-3 
            sm:gap-4 
        `}>
            <dt className={classNames(`
                text-sm 
                font-medium 
                leading-6 
                text-gray-900
                pl-4
                ${positionTile}
            `)}>{title}</dt>
            {
                editProperty ?
                    (
                        <dd className={`
                        mt-1 
                        text-sm 
                        leading-6 
                        text-gray-700 
                        sm:col-span-2 
                        sm:mt-0
                        pl-4
                    `}>
                            <div className="w-full flex justify-between pr-8">
                                <p>{description}</p>
                                <Tooltip title={`Editar ${title}`} TransitionComponent={Zoom} placement='right'>
                                    <button
                                        type="button"
                                        onClick={() => setEditProperty(true)}
                                        className="flex justify-center shadow-sm shadow-orange-500 bg-orange-500 uppercase px-2 py-1 text-slate-50 rounded hover:bg-orange-600"
                                    >
                                        <PencilSquareIcon className="block h-4 w-4" aria-hidden="true" />
                                    </button>
                                </Tooltip>

                            </div>
                        </dd>
                    ) : (
                        <dd className={`
                        mt-1 
                        text-sm 
                        leading-6 
                        text-gray-700 
                        sm:col-span-2 
                        sm:mt-0
                        pl-4
                    `}>{description}</dd>
                    )
            }
        </div>
    )
}
