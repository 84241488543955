import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';

import { forgotPasswordEmail } from '../../redux/actions/user';
import { validateEmail } from '../../common/validations';

import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { InputText } from '../../components/inputs/InputText';

import logo from '../../static/image/logo.png';
import image from '../../static/image/7.png';


export const FormForgetPasswordEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
    });


    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        const { email } = formData;
        const emailValid = validateEmail(email);

        if (!emailValid) {
            toast.error('Ingresa un correo electronico valido');
            setLoading(false);
        }

        if (emailValid) {
            dispatch(forgotPasswordEmail(email))
                .then((result) => {
                    if (result.status === 200) {
                        toast.success(result.message);
                        navigate('/');
                    }
                    if (result.status === 400) {
                        toast.error(result.message);
                    }
                    setLoading(false);
                });
        }
    }

    const renderStep = () => (
        <div
            className='w-full h-screen bg-white flex flex-col justify-between'
            style={{ height: "100dvh" }}
        >
            <div className='mt-[100px]'>
                <div className='flex justify-center items-center'>
                    <div className='w-[45%] md:w-[40%]'>
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div className='flex justify-center items-center mt-4'>
                    <div className='w-[85%] md:w-2/3'>
                        <h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
                            Para recuperar tu contraseña ingresa tu correo electronico y sigue las intruciones del correo electronico.
                        </h2>
                    </div>
                </div>
                <div className='flex justify-center items-center mt-8'>
                    <form
                        className='w-[85%] md:w-2/3 flex flex-col juctify-center items-center gap-8'
                        onSubmit={(e) => handleSendEmail(e)}
                    >
                        <div className='w-full'>
                            <InputText
                                id={'email'}
                                name={'email'}
                                type={'email'}
                                label={'Correo Electronico'}
                                onChange={(e) => onChange(e)}
                                value={formData.email}
                                placeholder={'Ingresa tu correo electronico'}
                                disabled={false}
                            />
                        </div>
                        <div className='w-full'>
                            <button
                                type='submit'
                                disabled={loading}
                                className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 w-full'
                            >
                                {loading
                                    ?
                                    <ButtonLoader />
                                    : 'Solicitar nueva contraseña'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='flex justify-center items-center mt-8'>
            </div>
            {/* Footer */}
            <div className='w-full flex mb-[60px]'>
                <div className='w-full flex justify-center text-xs text-gray-400 mt-4'>
                    <p className='text-center'>Derechos Reservados © Centro de Formación Académica 2024</p>
                </div>
            </div>
        </div>
    );

    return (
        <div className='w-full flex'>
            <div className='w-full h-screen md:w-[40%]'>
                {renderStep()}
            </div>
            <div
                className='w-0 md:w-[60%] h-screen bg-cover'
                style={{ backgroundImage: `url(${image})` }}
            ></div>
        </div>
    );
};

