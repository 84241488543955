import { RadioGroup } from '@headlessui/react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const InputAccountBank = ({ accountsBank, accountSelected, setAccountSelected, numColumns = 2 }) => {
    const handleAccount = (value) => {
        const accout = accountsBank.find(account => account.value === value)
        setAccountSelected({ ...accountSelected, account: { '_id': accout.value, 'name': accout.description } });
    }
    return (
        <div>
            {accountsBank && (
                <RadioGroup value={accountSelected?.level?._id} onChange={(value) => handleAccount(value)}>
                    <RadioGroup.Label className="sr-only">Seleciona el nivel del curso</RadioGroup.Label>
                    <div className={`grid grid-cols-1 lg:grid-cols-${numColumns} gap-4`}>
                        {accountsBank.map(({ value, name, description, cable, urlName }) => (
                            <RadioGroup.Option
                                key={value}
                                value={value}
                                className={({ active }) =>
                                    classNames(
                                        active ? 'ring-2 ring-indigo-500' : '',
                                        'cursor-pointer bg-white text-gray-900 shadow-sm group relative flex items-center justify-center rounded-md border px-3 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                    )
                                }
                            >
                                {({ active, checked }) => (
                                    <>
                                        <div className='w-full flex min-h-[130px]'>
                                            <div className='flex justify-center items-center px-4'>
                                                {urlName && (
                                                    <img className='w-[54px]' src={urlName} alt="" />
                                                )}
                                            </div>
                                            <div className=' flex flex-col justify-center items-start pl-4'>

                                                <p className='text-[10px] text-gray-600 tracking-wide uppercase'>Nombre de cuenta</p>
                                                <p className='text-[12px] text-gray-900 font-semibold tracking-wide uppercase'>{name}</p>
                                                {description && (
                                                    <div>
                                                        <p className='text-[10px] text-gray-600 tracking-wide uppercase'>Numero de cuenta</p>
                                                        <p className='text-[12px] text-gray-900 font-semibold tracking-wide uppercase'>{description}</p>
                                                    </div>
                                                )}
                                                {cable && (
                                                    <div>
                                                        <p className='text-[10px] text-gray-600 tracking-wide uppercase'>Cable</p>
                                                        <p className='text-[12px] text-gray-900 font-semibold tracking-wide uppercase'>{cable ? cable : '-'}</p>

                                                    </div>
                                                )}
                                            </div>
                                            { }

                                        </div>
                                        <span
                                            className={classNames(
                                                active ? 'border' : 'border-2',
                                                checked ? 'border-indigo-500' : 'border-transparent',
                                                'pointer-events-none absolute -inset-px rounded-md'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
            )}
        </div>
    )
}
