import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

import { getAllUsers } from '../../redux/actions/users';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { AddNewUserModal } from './AddNewUserModal';
import { UserIcon, UserPlusIcon } from '@heroicons/react/20/solid';
import { Loading } from '../../components/loading/Loading';
import { Tooltip, Zoom } from '@mui/material';

export const UsersPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllUsers())
	}, [])

	const {
		users,
		loading,
	} = useSelector((state) => state.users);

	const [openAddNewUserModal, setOpenAddNewUserModal] = useState(false)
	const [searchText, setSearchText] = useState('');

	const rows = users;

	const columns = [
		{
			field: 'firstName',
			headerName: 'Nombre',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'lastName',
			headerName: 'Apellido',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{ field: 'email', headerName: 'Correo Electronico', flex: 1 },
		{
			field: 'typeUser',
			headerName: 'Tipo de usuario',
			flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{ field: 'createdAtFormatted', headerName: 'Fecha de registro', flex: 1 },
		{
			field: 'action',
			headerName: '',
			flex: 1,
			sortable: false,
			disableColumnMenu: true,
			renderCell: (params) => (
				<div className='w-full flex justify-center gap-4'>
					<Tooltip title="Ver perfil de usuario" TransitionComponent={Zoom} placement='left'>
						<Link
							to={`/perfil/${params.id}`}
							className="flex justify-center shadow-sm shadow-indigo-600 bg-indigo-600 uppercase px-4 py-2 text-slate-50 rounded hover:bg-indigo-800"
						>
							<UserIcon className="block h-5 w-5" aria-hidden="true" />
						</Link>
					</Tooltip>
				</div>
			),
		},
	];

	const handleSearchChange = (event) => {
		const searchText = event.target.value;
		setSearchText(searchText);
	};

	const filteredRows = rows.filter(
		(row) =>
			row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.typeUser?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.createdAtFormatted?.toLowerCase().includes(searchText.toLowerCase())
	);

	return (
		<ContainerFull>
			<Heading
				title={`Lista de personal`}
				subtitle={`Muestra la lista de todo el personal registrados en el plataforma.`}
				center={false}
			/>

			<div className='w-full flex justify-end mt-4'>
				<Tooltip title="Agregar un nuevo usuario administrativo" TransitionComponent={Zoom} placement='top'>
					<button
						type='button'
						onClick={() => setOpenAddNewUserModal(true)}
						className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
					><UserPlusIcon className="block h-5 w-5" aria-hidden="true" /></button>
				</Tooltip>
			</div>

			{users && (
				<>
					<Wrapper>
						<input
							type="text"
							placeholder="Buscar a un usuario"
							value={searchText}
							onChange={handleSearchChange}
							className='block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
					</Wrapper>

					<Wrapper>
						{
							loading ? (
								<Loading />
							) : (
								<>
									{
										rows.length > 0
											? (
												<div className='w-full overflow-x-auto'>
													<DataGrid
														rows={filteredRows}
														columns={columns}
														// onRowClick={handleRowClick}
														localeText={esES.components.MuiDataGrid.defaultProps.localeText}
														initialState={{
															pagination: {
																paginationModel: { pageSize: 10, page: 0, },
															},
														}}
														pageSizeOptions={[10, 15, 25]}
													/>
												</div>
											) : (
												<p className='text-center text-red-500'>No se encontraron resultados.</p>
											)
									}
								</>
							)

						}
					</Wrapper>
				</>

			)}
			<AddNewUserModal
				open={openAddNewUserModal}
				setOpen={setOpenAddNewUserModal}
			/>
		</ContainerFull>
	);
}
