import { createSlice } from '@reduxjs/toolkit';

const noteUserSlice = createSlice({
    name: 'noteUser',
    initialState: {
        loading: false,
        notes: [],
    },
    reducers: {
        getAllNotesByUserIdStart: (state) => {
            state.loading = true;
        },
        getAllNotesByUserIdSuccess: (state, { payload: { notes } }) => {
            state.loading = false;
            state.notes = notes;
        },
        getAllNotesByUserIdFailure: (state) => {
            state.loading = false;
        },
        createNoteUserStart: (state) => {
            state.loading = true;
        },
        createNoteUserSuccess: (state) => {
            state.loading = false;
        },
        createNoteUserFailure: (state) => {
            state.loading = false;
        },
        deleteNoteUserStart: (state) => {
            state.loading = true;
        },
        deleteNoteUserSuccess: (state) => {
            state.loading = false;
        },
        deleteNoteUserFailure: (state) => {
            state.loading = false;
        },
        cleanUserNote: (state, action) => {
			state.notes = [];
			state.loading = false;
        }
    }
});

export const {
    getAllNotesByUserIdStart,
    getAllNotesByUserIdSuccess,
    getAllNotesByUserIdFailure,
    createNoteUserStart,
    createNoteUserSuccess,
    createNoteUserFailure,
    deleteNoteUserStart,
    deleteNoteUserSuccess,
    deleteNoteUserFailure,
    cleanUserNote,
} = noteUserSlice.actions;
export default noteUserSlice.reducer;